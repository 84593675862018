import {ApiGetRequest, ApiPostRequest, ApiDeleteRequest} from "@/utils/Api";
import {Message, MessageBox} from "element-ui";
import moment from "moment";
import KeuanganSekolahService from "@/services/KeuanganSekolahService";
import store from "@/store"

const state = {
	data: {
		subMenuKeuangan: [
			{
				name: "KeuanganMutasi",
				label: "Mutasi",
				lock: false,
				url: "/keuangan/mutasi",
			},
			{
				name: "KeuanganPenarikan",
				label: "Penarikan",
				lock: false,
				url: "/keuangan/penarikan",
			},
			{
				name: "KeuanganAkunBank",
				label: "Akun Bank",
				lock: false,
				url: "/keuangan/akun-bank",
			},
		],
		showModalPenarikan: false,
		showModalConfirm: false,
		detailConfirm: null,
		balance: 0,
		itemsAllByStartDate: [],
		items: [],
		items_recap: [],
		columns_penarikan: [
			{
				prop: "transaction_id",
				label: "ID",
				width: "100",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "created_at",
				label: "Waktu Penarikan",
				width: "160",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "bank_account_number",
				label: "No Rekening",
				minWidth: "150",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "amount",
				label: "Nominal",
				width: "160",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "status",
				label: "Status",
				width: "100",
				"class-name": "body-14 col-black-2 text-center",
			},
			{
				prop: "rejection_reason",
				label: "Pesan Pembatalan",
				minWidth: "100",
				"class-name": "body-14 col-black-2",
			},
		],
		columns_mutasi: [],
		columns_bank: [],
		periode: "2",
		tglawal: moment()
			.clone()
			.startOf("month")
			.format("YYYY-MM-DD"),
		tglakhir: moment()
			.clone()
			.endOf("month")
			.format("YYYY-MM-DD"),
		loading: false,
		total: 0,
		page: 1,
		limit: 10,
		filter: "",
		sort_field: "",
		sort_dir: "",
		filterBank: "",
		daftarBank: [],
		daftarakunbank: [],
		ruleFormBank: {
			daftarbank: "",
			norek: "",
			atasnama: "",
			selectedBank: "",
		},
		rulesBank: {
			daftarbank: [
				{
					required: true,
					message: "Anda wajib mengisi Daftar Bank",
					trigger: "blur",
				},
				{
					validator: (rule, value, callback) => {
						if (value) {
							if (
								(!state.data.ruleFormBank.selectedBank ||
									state.data.ruleFormBank.selectedBank === "") &&
								value !== ""
							) {
								callback(new Error("Data bank tidak valid"));
							} else {
								callback();
							}
						}
					},
					trigger: "change",
				},
			],
			norek: [
				{
					required: true,
					message: "Anda wajib mengisi No rekening",
					trigger: "blur",
				},
			],
			atasnama: [
				{
					required: true,
					message: "Anda wajib mengisi Nama Pemilik rekening",
					trigger: "blur",
				},
			],
		},
		ruleFormPenarikan: {
			nominal: "",
			bank: "",
			atasNama: "",
			keterangan: "",
		},
		rulesPenarikan: {
			nominal: [
				{
					required: true,
					message: "Anda wajib mengisi nominal",
					trigger: "blur",
				},
				{
					validator: (rule, value, callback) => {
						if (value) {
							if (value === 0) {
								callback(new Error("Nominal tidak boleh 0"));
							} else {
								callback();
							}
						} else {
							callback(new Error("Nominal tidak boleh 0"));
						}
					},
					trigger: "blur",
				},
			],
			bank: [
				{
					required: true,
					message: "Anda wajib mengisi bank",
					trigger: "blur",
				},
			],
			atasNama: [
				{
					required: true,
					message: "Anda wajib mengisi nama pemilik akun",
					trigger: "blur",
				},
			],
			// keterangan: [
			//   {
			//     required: true,
			//     message: 'Anda wajib mengisi keterangan',
			//     trigger: 'blur',
			//   },
			// ],
		},
	},
};

const mutations = {
	changeKeuangan(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
};

const actions = {
	async getListMutasi({commit, state}, payload = null) {
		commit("changeKeuangan", {
			loading: true,
		});

		if (payload) {
			await commit("changeKeuangan", {
				page: payload || state.data.page,
			});
		}

		await commit("changeKeuangan", {
			loading: true,
		});

		const {data} = state;

		let params = {
			page: data.page,
			limit: data.limit,
			q: data.filter,
			sort_field: data.sortBy || "",
			sort_dir: data.sortType || "",
		};

		if (
			data.periode !== "1" &&
			data.tglawal &&
			data.tglawal !== "" &&
			data.tglakhir &&
			data.tglakhir !== ""
		) {
			params.date_start = moment(data.tglawal).format("YYYY-MM-DD");
			params.date_end = moment(data.tglakhir).format("YYYY-MM-DD");
		}

		const result = await ApiGetRequest(
			`klaspay/transaction/school_mutation`,
			params
		);
		/**
		 * {
		 *     "rc": "403",
		 *     "rd": "Token Error: [AzBXgPxo3Fhi032X] Unexpected token o in JSON at position 1",
		 *     "timestamp": "8/16/2024, 3:33:57 AM",
		 *     "path": "/api/klaspay/transaction/school_mutation?page=1&limit=10&q=&sort_field=&sort_dir=&date_start=2024-08-01&date_end=2024-08-31",
		 *     "method": "GET"
		 * }
		 * */

		commit("changeKeuangan", {
			loading: false,
		});

		const code = parseInt(result.data.rc);

		if (result.error || code >= 400) {
			if (code >= 403) {
				Message({
					type: "error",
					message: result.data.rd,
				});
				return false
			}
			Message({
				type: "error",
				message: "Gagal menampilkan data Mutasi Keuangan",
			});

			return false;
		}
		if (typeof result.data.data === "undefined") {
			Message({
				type: "error",
				message: "Gagal menampilkan data Mutasi Keuangan",
			});
			return false
		}
		commit("changeKeuangan", {
			items: result.data.data.transaction_invoice || [],
			total: result.data.data.meta.total,
		});

		return true;
	},
	async getListMutasiByStartDate({commit}, payloadDate = null) {

		const filterDate = moment(payloadDate).format("YYYY-MM-DD")
		let params = {
			page: 1,
			perpage: 5000,
			date_start: filterDate,
			date_end: filterDate,
			sort_field: "created_at",
			sort_dir: "asc",
		};

		const result = await ApiGetRequest(`klaspay/transaction/school_mutation`, params);

		commit("changeKeuangan", {
			itemsAllByStartDate: []
		});
		if (result.code >= 500) {
			MessageBox({
				type: "error",
				message: "Layanan tidak tersedia, Coba lagi nanti ya...",
			});
			return false;
		}

		if (result.error) {
			Message({
				type: "error",
				message: result.error || "Gagal menampilkan data Mutasi Keuangan",
			});

			return false;
		}

		const res = result.data.data.transaction_invoice.sort((a, b) => {
			return parseInt(a.transaction_id) - parseInt(b.transaction_id);
		});

		commit("changeKeuangan", {
			itemsAllByStartDate: res,
		});
		return true;
	},

	async getRecapMutasi({commit, state}, payload = null) {
		commit("changeKeuangan", {
			loading: true,
		});

		if (payload) {
			await commit("changeKeuangan", {
				page: payload || state.data.page,
			});
		}

		await commit("changeKeuangan", {
			loading: true,
		});

		const {data} = state;
		let params = {
			page: data.page,
			limit: data.limit,
			q: data.filter,
			sort_field: data.sortBy || "",
			sort_dir: data.sortType || "",
		};

		if (
			data.periode !== "1" &&
			data.tglawal &&
			data.tglawal !== "" &&
			data.tglakhir &&
			data.tglakhir !== ""
		) {
			params.month_start = moment(data.tglawal).format("MM");
			params.year_start = moment(data.tglawal).format("YYYY");
			params.month_end = moment(data.tglakhir).format("MM");
			params.year_end = moment(data.tglakhir).format("YYYY");
			console.log("masuk sini 1");
		}

		if (!params.month_start || !params.year_start) {
			params.month_start = moment().format("M");
			params.year_start = moment().format("YYYY");
		}

		if (!params.month_end || !params.year_end) {
			params.month_end = moment().format("M");
			params.year_end = moment().format("YYYY");
		}

		const result = await ApiGetRequest(`klaspay/mutation/recap`, params);

		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data Mutasi Keuangan",
			});

			commit("changeKeuangan", {
				loading: false,
			});

			return false;
		} else {
			commit("changeKeuangan", {
				items_recap: result.data.data || {},
				loading: false,
			});

			return true;
		}
	},

	async getAllAkunBank({commit}) {
		const result = await ApiGetRequest(`school/bank-account`);
		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data Akun Bank",
			});

			commit("changeKeuangan", {
				loading: false,
			});

			return false;
		} else {
			commit("changeKeuangan", {
				daftarakunbank: result.data.data || [],
			});

			return true;
		}
	},

	async getAkunBank({commit, state}, payload = null) {
		if (payload) {
			await commit("changeKeuangan", {
				page: payload || state.data.page,
			});
		}

		await commit("changeKeuangan", {
			loading: true,
		});

		const {data} = state;
		let params = {
			page: data.page,
			limit: data.limit,
			q: data.filter,
			sort_field: data.sortBy || "",
			sort_dir: data.sortType || "",
		};

		const result = await ApiGetRequest(`school/bank-account`, params);

		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data Akun Bank",
			});

			commit("changeKeuangan", {
				loading: false,
			});

			return false;
		} else {
			commit("changeKeuangan", {
				items: result.data.data || [],
				total: (result.data.meta && result.data.meta.total) || 0,
				loading: false,
			});

			return true;
		}
	},

	async getSaldo({commit}) {
		await commit("changeKeuangan", {
			loading: true,
		});

		const result = await ApiGetRequest(`school/balance`);
		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data total saldo",
			});

			commit("changeKeuangan", {
				loading: false,
			});

			return false;
		} else {
			commit("changeKeuangan", {
				balance: result.data.data.balance || 0,
				loading: false,
			});

			return true;
		}
	},

	async getListPenarikan({commit, state}, payload = null) {
		if (typeof payload === "number") {
			await commit("changeKeuangan", {
				page: payload || state.data.page,
			});
		}

		await commit("changeKeuangan", {
			loading: true,
		});

		const {data} = state;
		let params = {
			page: data.page,
			limit: data.limit,
			q: data.filter,
		};

		if (
			data.periode !== "1" && data.tglawal && data.tglawal !== "" && data.tglakhir && data.tglakhir !== ""
		) {
			params.tglawal = moment(data.tglawal).format("YYYY-MM-DD");
			params.tglakhir = moment(data.tglakhir).format("YYYY-MM-DD");
		}

		try {

			const keuanganService = new KeuanganSekolahService()
			const result = await keuanganService.withdrawGet(params)

			commit("changeKeuangan", {
				items: result.data.data?.transactions || [],
				total: result.data?.data?.total || result.data.data?.transactions?.length,
				loading: false,
			});

			return true;
		} catch (e) {
			console.log(e)
			Message({
				type: "error",
				message: "Gagal menampilkan data List Penarikan Keuangan",
			});

			commit("changeKeuangan", {
				loading: false,
			});

			return false;
		}

	},

	async getListMasterBank({commit, state}, payload = null) {
		const {data} = state;
		let params = {
			q: payload.search || "",
		};
		const result = await ApiGetRequest(`school/bank`, params);
		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menampilkan data master bank",
			});

			commit("changeKeuangan", {
				loading: false,
			});

			return false;
		} else {
			let resultDataBank = result.data.data.map((item) => {
				return {value: item.name, id: item.id};
			});

			commit("changeKeuangan", {
				daftarBank: result.data.data || [],
				ruleFormBank: {
					...data.ruleFormBank,
					selectedBank:
						result.data.data.length > 0 ? data.ruleFormBank.selectedBank : "",
				},
				loading: false,
			});

			return resultDataBank || [];
		}
	},

	async createAkunBank({commit, state}) {
		commit("changeKeuangan", {
			loading: true,
		});

		const {data} = state;

		let formData = {
			bank: data.ruleFormBank.selectedBank,
			account_number: data.ruleFormBank.norek,
			account_holder: data.ruleFormBank.atasnama,
		};

		let result = await ApiPostRequest(`school/bank-account`, formData);
		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menambahkan akun bank baru",
			});

			commit("changeKeuangan", {
				loading: false,
				error: result.errorList || false,
			});

			return false;
		} else {
			commit("changeKeuangan", {
				loading: false,
				error: false,
			});

			Message({
				type: "success",
				message: "Data akun bank berhasil ditambahkan",
			});

			return true;
		}
	},

	async deleteAkunBank({commit}, payload = null) {
		commit("changeKeuangan", {
			loading: true,
		});

		let result = await ApiDeleteRequest(`school/bank-account/${payload.id}`);
		if (result.error) {
			Message({
				type: "error",
				message: "Gagal menghapus data akun",
			});

			commit("changeKeuangan", {
				loading: false,
			});

			return false;
		} else {
			commit("changeKeuangan", {
				loading: false,
				error: false,
			});

			Message({
				type: "success",
				message: "Data akun berhasil dihapus",
			});

			return true;
		}
	},

	async pembatalanPenarikan({commit}, payload = null) {
		commit("changeKeuangan", {
			loading: true,
		});

		try {
			const keuanganService = new KeuanganSekolahService()
			await keuanganService
		}catch (e) {
			console.log(e)
		}
		let result = await ApiDeleteRequest(`school/withdraw/${payload.id}`);
		if (result.error) {
			Message({
				type: "error",
				message: "Gagal membatalkan penarikan",
			});

			commit("changeKeuangan", {
				loading: false,
			});

			return false;
		} else {
			commit("changeKeuangan", {
				loading: false,
				error: false,
			});

			Message({
				type: "success",
				message: "Data penarikan dana berhasil dibatalkan",
			});

			return true;
		}
	},

	async createPenarikan({commit, state}) {
		const {data} = state;

		let userUuid = store.getters.userUuid
		let userName = store.getters.userName
		let userPhone = store.getters.userPhone
		let userRoleToArray = store.getters.userRoleToArray
		let schoolId = store.getters.schoolId
		let schoolName = store.getters.schoolName

		if (!userUuid || !userName || !userPhone || !userRoleToArray || !schoolId || !schoolName) {
			Message({
				type: "info",
				message: "Data Anda belum lengkap. pastikan nomor hp diisi",
			});
			return false
		}

		commit("changeKeuangan", {
			loading: true,
			error: false,
		});

		try {
			const formData = {
				...data.ruleFormPenarikan,
				bank_account_id: data.ruleFormPenarikan.atasNama,
				executor: {
					"user_uuid": userUuid, //string
					"user_name": userName, //string
					"user_phone": userPhone, //string
					"user_role_name": userRoleToArray, // array
					"school_id": schoolId, // int
					"school_name": schoolName //string
				}
			}

			const keuanganService = new KeuanganSekolahService()
			await keuanganService.withdrawPost(formData)


			commit("changeKeuangan", {
				loading: false,
				error: false,
			});

			Message({
				type: "success",
				message: "Data penarikan berhasil ditambahkan, Tinggal menunggu Persetujuan",
			});

			return true;
		} catch (error) {
			Message({
				type: "error",
				message: `Gagal melakukan penarikan, mohon mencoba kembali | ${error.error}`,
			});

			commit("changeKeuangan", {
				loading: false,
				error: error.errorList || false,
			});

			return false;
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
