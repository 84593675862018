import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import KeuanganSekolahService from "@/services/KeuanganSekolahService";
import moment from "moment";
import {NO_INTERNET_MESSAGE} from "@/services/messageService";

const state = {
	data: {
		showModalTunggakan: false,
		showModalSelectMonth: false,
		loading: false,
		filtertype: "1",
		filterPeriode: null,
		filterYearMonth: null,
		filterRangeDate: [],
		filterTglAwal: null,
		filterTglAkhir: null,
		dataPrint: false,
		itemsMessageTable: null,
		errorDataItems: null,
		items_invoice: [],
		limit: 10,
		page: 1,
		total: 0,
		items: [],
		sortField: "",
		sortDir: "",
		rekapMutasiByRangeDate: {
			loading: false,
			items: [],
			errorDataItems: null,
		},
		columns: [
			{
				prop: "created_t",
				label: "Tanggal",
				width: 150,
				sortable: true,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "type",
				label: "Jenis Layanan",
				width: 160,
				"class-name": "body-14 col-black-2 text-left",
			},
			{
				prop: "type_text",
				label: "Tipe Layanan",
				minWidth: "170",
				"class-name": "body-14 col-black-2 text-left",
			},
			{
				prop: "transaction_id",
				label: "Kode Transaksi",
				minWidth: "140",
				"class-name": "body-14 col-black-2 text-center",
			},
			{
				prop: "activitas",
				label: "Aktivitas",
				width: 120,
				"class-name": "body-14 col-black-2 text-center",
			},
			{
				prop: "nominal",
				label: "Nominal",
				width: 180,
				"class-name": "body-14 col-black-2 text-left",
			},
		],
		rekapMutasiDay: {
			loading: false,
			spp_dibayar: [],
			items: [],
			errorDataItems: null,
		},
		rekapMutasi: {
			loading: false,
			serverError: false,
			messageError: null,
			detailRecap: {
				"wallet_id": "",
				"name": "",
				"balance": 0,
				"difference": 0,
				"period": "",
				"total_debet": 0,
				"total_kredit": 0,
			},
			items: [
				{
					"month": 0,
					"year": 0,
					"mutation_data": [
						{
							"mutation_id": 0,
							"debet": 0,
							"kredit": 0,
							"previous_balance": 0,
							"current_balance": 0,
							"reff_transaction_id": "",
							"note": "",
							"note2": "",
							"created_t": 0,
							"month": 0,
							"year": 0
						}
					],
					"begining_balance": 0,
					"ending_balance": 0
				}
			],
			errorDataItems: null,
		}
	}
};

const mutations = {
	changeKeuanganMutasi(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeKeuanganMutasiLoading(state, payload) {
		state.data.loading = payload
	},

	changeKeuanganMutasiRecap(state, payload) {
		state.data.rekapMutasi = Object.assign({}, state.data.rekapMutasi, payload);
	},
	changeKeuanganMutasiRecapDetail(state, payload) {
		state.data.rekapMutasi.detailRecap = {
			wallet_id: payload.wallet_id || "",
			name: payload.name || "",
			balance: payload.balance || 0,
			difference: payload.difference || 0,
			period: payload.period || "",
			total_debet: payload.total_debet || 0,
			total_kredit: payload.total_kredit || 0,
		}
	},
	changeKeuanganMutasiRecapLoading(state, payload) {
		state.data.rekapMutasi.loading = payload
	},

	changeKeuanganMutasiRecapDaily(state, payload) {
		state.data.rekapMutasiDay = Object.assign({}, state.data.rekapMutasiDay, payload);
	},
	changeKeuanganMutasiRecapDailyLoading(state, payload) {
		state.data.rekapMutasiDay.loading = payload
	},

	changeKeuanganMutasiRecapRangeDate(state, payload) {
		state.data.rekapMutasiByRangeDate = Object.assign({}, state.data.rekapMutasiByRangeDate, payload);
	},
	changeKeuanganMutasiRecapRangeDateLoading(state, payload) {
		state.data.rekapMutasiByRangeDate.loading = payload
	},

	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
};

const actions = {

	async onShowModalSelectMonth({commit}, isOpen) {
		commit("changeKeuanganMutasi", {showModalSelectMonth: isOpen})
	},

	async fetchSchoolInvoiceMutation({commit, state}) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeKeuanganMutasiLoading", true)

		const params = {
			page: data.page,
			limit: data.limit,
			sort_field: data.sortField,
			sort_dir: data.sortDir,
			filterDateStart: data.filterRangeDate && data.filterRangeDate[0] || undefined,
			filterDateEnd: data.filterRangeDate && data.filterRangeDate[1] || undefined
		};

		try {
			const service = new KeuanganSekolahService()
			const result = await service.schoolMutationGet(params)

			const datases = result.data.data?.transaction_invoice || []
			if (result.data?.data?.transaction_invoice) {
				if (!datases.length) {
					let m = data.filterQ ? "Pencarian Tidak Ditemukan" : "Belum terdapat Pembayaran"
					commit("setMessageItemsTable", m)
				} else {
					commit("setMessageItemsTable", null)
				}

				commit("changeKeuanganMutasi", {
					items: datases,
					total: (result.data.data?.meta && result.data.data?.meta.total) || datases.length,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {
			console.log(e)

			commit("changeKeuanganMutasi", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("setDataItemsError", NO_INTERNET_MESSAGE)
				commit("setMessageItemsTable", NO_INTERNET_MESSAGE)
				return false
			}

			let m = e.error ? e.error : "Gagal Mengambil Data Rekap"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeKeuanganMutasiLoading", false)
		}
	},

	async fetchSchoolInvoiceMutationRecap({commit, state}) {

		const {rekapMutasi} = state.data

		if (rekapMutasi.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeKeuanganMutasiRecapLoading", true)

		const monthStart = moment(state.data.filterRangeDate[0], "YYYY-MM-DD").format("MM")
		const yearStart = moment(state.data.filterRangeDate[0], "YYYY-MM-DD").format("YYYY")

		const monthEnd = moment(state.data.filterRangeDate[1], "YYYY-MM-DD").format("MM")
		const yearEnd = moment(state.data.filterRangeDate[1], "YYYY-MM-DD").format("YYYY")

		const params = {
			page: 1,
			limit: 0,

			month_start: monthStart,
			year_start: yearStart,

			month_end: monthEnd,
			year_end: yearEnd,

			sort_field: "created_at",
			sort_dir: "asc",
		};

		try {
			const service = new KeuanganSekolahService()
			const result = await service.schoolMutationRecapGet(params)

			commit("changeKeuanganMutasiRecapDetail", result.data?.data)

			const datases = result.data.data?.mutation_list || []
			if (result.data?.data?.mutation_list) {
				commit("changeKeuanganMutasiRecap", {
					items: datases,
				})
			}

			return true
		} catch (e) {
			console.log(e)


			commit("changeKeuanganMutasiRecapDetail", {
				"wallet_id": "",
				"name": "",
				"balance": 0,
				"difference": 0,
				"period": "",
				"total_debet": 0,
				"total_kredit": 0,
			})

			commit("changeKeuanganMutasiRecap", {
				items: [],
			})

			let m = e.error ? e.error : "Gagal Mengambil Data Rekap"
			if (e.status >= 500) {
				m = SERVER_ERROR_MESSAGE
			} else if (e.code === "ERR_NETWORK") {
				m = NO_INTERNET_MESSAGE
			}
			commit("changeKeuanganMutasiRecap", {
				serverError: true,
				messageError: `${e.status} | ${m}`
			})

			Message({
				type: "error",
				message: m
			});

			return false;
		} finally {
			commit("changeKeuanganMutasiRecapLoading", false)
		}
	},

	async fetchDailySchoolInvoiceRecap({commit, state}, dateNow) {

		const {rekapMutasiDay} = state.data

		if (rekapMutasiDay.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeKeuanganMutasiRecapDailyLoading", true)

		const params = {
			page: 1,
			limit: 5000,
			filterDateStart: dateNow,
			filterDateEnd: dateNow,
			sort_field: "created_at",
			sort_dir: "asc",
		};

		try {
			const service = new KeuanganSekolahService()
			const result = await service.schoolMutationGet(params)

			const datases = result.data.data?.transaction_invoice || []
			if (result.data?.data?.transaction_invoice) {
				commit("changeKeuanganMutasiRecapDaily", {
					items: datases,
				})
			}

			return true
		} catch (e) {
			console.log(e)

			commit("changeKeuanganMutasiRecapDaily", {
				items: [],
			})

			let m = e.error ? e.error : "Gagal Mengambil Data Rekap"
			if (e.status >= 500) {
				m = SERVER_ERROR_MESSAGE
			} else if (e.code === "ERR_NETWORK") {
				m = NO_INTERNET_MESSAGE
			}

			Message({
				type: "error",
				message: m
			});

			return false;
		} finally {
			commit("changeKeuanganMutasiRecapDailyLoading", false)
		}
	},

};

const getters = {

	dataMonthsComputed: (state) => {
		if (!state.data.rekapMutasiByRangeDate.month) {
			return [];
		}

		const startDate = moment(state.data.rekapMutasiByRangeDate.month[0]);
		const endDate = moment(state.data.rekapMutasiByRangeDate.month[1]);

		//const duration = moment.duration(endDate.diff(startDate));
		const months = endDate.diff(startDate, "months");
		const monthArray = [];
		for (let i = 0; i <= months; i++) {
			const currentMonth = startDate.clone().add(i, "months");
			const label = currentMonth.format("MMM YY");
			monthArray.push({
				date: currentMonth.format("YYYY-MM"),
				label: label,
				issued_at: currentMonth.format("YYYY-MM") + `-01 00:00:00`
			});
		}
		return monthArray;
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
