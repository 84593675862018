import {
  ApiGetRequest,
  ApiPostRequest,
  ApiDeleteRequest,
  ApiPutRequest,
  ApiGetRequestPartisipasi,
  ApiPostRequestPartisipasi
} from "@/utils/Api";
import { Message } from "element-ui";
import moment from "moment";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import PembayaranSppService from "@/services/PembayaranSppService";

const state = {
  data: {
		subMenuSpp: [
			{
				label: 'Tunggakan Pelajar',
				name: 'TunggakanPelajar',
				url: '/pembayaran-spp/tunggakan-pelajar',
			},
			{
				label: 'Monitoring Pembayaran',
				name: 'PembayaranKelas',
				url: '/pembayaran-spp/pembayaran-kelas',
				lock: false,
			},
			{
				label: 'Riwayat Pembayaran',
				name: 'RiwayatPembayaran',
				url: '/pembayaran-spp/riwayat-pembayaran',
				lock: false,
			},
			{
				label: 'Pengaturan',
				name: 'PengaturanPembayaran',
				url: '/pembayaran-spp/pengaturan',
				lock: false,
			},
			{
				label: 'Biaya Admin',
				name: 'BiayaAdminSPP',
				url: '/pembayaran-spp/biaya-admin',
				lock: true,
			},
		],
		dataTunggakan: {
			loading: false,
			filterStatus: "paid",
			filterMajor: null,
			filtertype: 1,
			filterQ: null,
			dataPrint: false,
			messageItemsTable: null,
			limit: 10,
			page: 1,
			total: 0,
			items: [],
			sortField: "",
			sortDir: "",
			columns: [
				{
					prop: "expand",
					label: "",
					type: "expand"
				},
				{
					prop: "name",
					label: "Nama",
					minWidth: "130",
					sortable: true,
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "nisn",
					label: "NISN / NIS",
					minWidth: "80",
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "classes",
					label: "Kelas",
					minWidth: "90",
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "major",
					label: "Jurusan",
					minWidth: "120",
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "school_fee",
					label: "Biaya SPP",
					minWidth: "80",
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "total_debt",
					label: "Tunggakan",
					minWidth: "80",
					"class-name": "body-14 col-black-2"
				},
				{
					fixed: "right",
					label: "Aksi",
					width: "90",
					"class-name": "body-14 col-black-2"
				}
			],
		},
    columns_tunggakan: [
      {
        prop: "month",
        label: "Bulan",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "discount_amount",
        label: "Diskon",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "fee",
        label: "SPP",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "total_fee",
        label: "Nominal Dibayarkan",
        minWidth: "120",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "status",
        label: "Status",
        width: "120",
        "class-name": "body-14 col-black-2"
      },
      {
        fixed: "right",
        label: "Aksi",
        width: "100",
        "class-name": "body-14 col-black-2"
      }
    ],
    columns_discount: [
      {
        prop: "nis_nik",
        label: "Bulan",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "name",
        label: "Diskon%",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "major",
        label: "Diskon Nominal",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "classes",
        label: "Tagihan",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "classes",
        label: "Nominal Dibayarkan",
        minWidth: "120",
        "class-name": "body-14 col-black-2"
      },
      {
        fixed: "right",
        label: "Status",
        width: "80",
        "class-name": "body-14 col-black-2"
      }
    ],
    columns_riwayat: [],
    columns_spp: [
      {
        prop: "name",
        label: "Kelas",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "school_fee",
        label: "Biaya SPP",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "student_discount_receiver",
        label: "Pengaturan Diskon",
        width: "200",
        "class-name": "body-14 col-black-2"
      }
    ],
    columns_penerima_diskon: [
      {
        prop: "name",
        label: "Nama",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "nis_nik",
        label: "NIS / NISN",
        minWidth: "80",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "classes",
        label: "Kelas",
        minWidth: "100",
        sortable: true,
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "major",
        label: "Jurusan",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "fee",
        label: "Biaya SPP",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "discount_amount",
        label: "Diskon",
        minWidth: "130",
        "class-name": "body-14 col-black-2"
      },
      {
        prop: "pembayaran",
        label: "Pembayaran",
        minWidth: "100",
        "class-name": "body-14 col-black-2"
      }
    ],
    penerima_diskon: [],
    month_name: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember"
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      precision: 0,
      masked: false
    },
    ruleForm: {
      biaya: "",
      kelas: "",
      start_month: "",
      start_year: "",
      end_year: "",
      end_month: "",
      jurusan: ""
    },
    rules: {
      biaya: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value || parseInt(value) === 0) {
              return callback(new Error("Inputkan biaya"));
            }

            callback();
          },
          message: "Inputkan biaya SPP",
          trigger: "blur"
        }
      ],
      kelas: [{ required: true, message: "Pilih Kelas", trigger: "blur" }],
      jurusan: [{ required: true, message: "Pilih Jurusan", trigger: "blur" }],
      start_month: [
        {
          required: true,
          message: "Input Bulan Periode",
          trigger: "blur"
        }
      ],
      start_year: [
        {
          required: true,
          message: "Input Tahun Periode",
          trigger: "blur"
        }
      ],
      end_month: [
        {
          required: true,
          message: "Input Bulan Periode",
          trigger: "blur"
        }
      ],
      end_year: [
        {
          required: true,
          message: "Input Tahun Periode",
          trigger: "blur"
        }
      ]
    },
    product_spp: [],
    riwayat_bayar: [],
    tunggakan_bayar: [],
    dataPrint: false,
    limit: 10,
    page: 1,
    total: 0,
    sortBy: "",
    sortType: "desc",
    filter: "",
    filterMajor: "",
    filterClasses: "",
    filterStatus: "paid",
    filtertype: "1",
    tglawal: "",
    tglakhir: "",
    loading: false,
    detail: {},
    discount: [],
    idProductSpp: 5,
    error: false,
    product_klaspay: {},
    items_invoice: []
  }
};

const mutations = {
  changeBayarSpp(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },

	changeBayarSppTunggakan(state, payload) {
    state.data.dataTunggakan = Object.assign({}, state.data.dataTunggakan, payload);
  },
	changeBayarSppTunggakanLoading(state, payload) {
		state.data.dataTunggakan.loading = payload
	},

  changeBayarSppLoading(state, payload) {
    state.data.loading = payload
  },
  setMessageItemsTable(state, payload) {
    state.data.dataTunggakan.messageItemsTable = payload
  }
};

const actions = {
  /**
   * @payload: monthArray required
   * */
  async getStudentInvoice({ commit }, payload) {
    const params = {};
    if (payload && payload.school_class_id) {
      params.class_id = payload.school_class_id;
    }
    if (payload && payload.student_id) {
      params.user_id = payload.student_id;
    }
    const urlParams = payload.monthArray
      .map(month => `months[]=${month.date}`)
      .join("&");
    const url = `?${urlParams}`;
    const result = await ApiGetRequest(`school/student-invoice${url}`, params);

    commit("changeBayarSpp", {
      items_invoice: []
    });
    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal mengambil data tagihan spp"
      });
      return false;
    }
    commit("changeBayarSpp", {
      items_invoice: result.data.data
    });

    return result.data.data || {};
  },
  async checkProductIdSpp({ commit }) {
    const result = await ApiGetRequestPartisipasi(
      `klaspay/external/onklasproduct/check`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data pelajar"
      });

      commit("changeBayarSpp", {
        product_klaspay: {}
      });

      return false;
    } else {
      let data_product = result.data.data || [];
      let product_spp = data_product.filter(item => {
        if (item.name.toLowerCase().indexOf("spp") !== -1) {
          return item;
        }
      });

      commit("changeBayarSpp", {
        product_klaspay:
          product_spp && product_spp.length > 0 ? product_spp[0] : {}
      });

      return result.data.data || {};
    }
  },

  async setAdminSpp(context, payload = null) {
    const result = await ApiPostRequestPartisipasi(
      `klaspay/external/onklasproduct/set/id/${payload.id}`,
      payload.data
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal setting biaya admin spp"
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Setting Biaya Admin berhasil"
      });

      return true;
    }
  },

  async searchPelajar(context, payload = null) {
    const result = await ApiGetRequest(
      `school/student?q=${(payload && payload.search) || ""}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data pelajar"
      });

      return [];
    } else {
      return result.data.data || [];
    }
  },

  async getDetailStudent({ commit }, payload = null) {
    const result = await ApiGetRequest(
      `school/student/${(payload && payload.id) || ""}`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data pelajar"
      });

      return [];
    } else {
      commit("changeBayarSpp", {
        detail: result.data.data
      });

      return true;
    }
  },

  async getDiscount({ commit }, payload = null) {
    const { data } = state;
    const result = await ApiGetRequest(
      `school/student/${(payload && payload.id) || ""}/discount?page=${
        data.page
      }&limit=${data.limit}&q=${data.filter}&sort_field=${
        data.sortBy
      }&sort_dir=${data.sortType}`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data discount"
      });

      commit("changeBayarSpp", {
        loading: false
      });

      return [];
    } else {
      commit("changeBayarSpp", {
        discount: result.data.data,
        loading: false
      });

      return true;
    }
  },

  async getInvoice({ commit, state }, payload = null) {
    const { data } = state;
    const result = await ApiGetRequest(
      `school/student/${(payload && payload.id) || ""}/invoice?page=${
        data.page
      }&limit=${data.limit}&q=${data.filter}&sort_field=${
        data.sortBy
      }&sort_dir=${data.sortType}`
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error ? result.error : "Gagal mengambil data invoice"
      });

      commit("changeBayarSpp", {
        loading: false
      });

      return [];
    } else {
      commit("changeBayarSpp", {
        invoices: result.data.data,
        loading: false
      });

      return true;
    }
  },

  async getTunggakan({ commit, state }, payload = null) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppLoading", true)

    if (payload) {
      await commit("changeBayarSpp", {
        page: payload || data.page
      });
    }

    const params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      show: 'active'
    };

    if (data.filterClasses) {
      params.school_class_id = data.filterClasses;
    }

		try {
			const service = new PembayaranSppService()
			const result = await service.studentDebtGet(params)

			if (result.data?.data) {
				commit("changeBayarSppTunggakan", {
					items: result.data.data,
					total: (result.data.meta && result.data.meta.total) || result.data.data.length,
				})
			}

			return true
		}catch (e) {

			commit("changeBayarSppTunggakan", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		}finally {
			commit("changeBayarSppLoading", false)
		}
  },

  async getRiwayatPembayaran({ commit, state }, payload = null) {
    commit("changeBayarSpp", {
      loading: true
    });

    if (payload) {
      await commit("changeBayarSpp", {
        page: payload || data.page
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      sort_field: "paid_at",
      sort_dir: "desc",
      classes: data.filterClasses,
      filter: data.filterStatus
    };

    if (
      data.tglawal &&
      data.tglawal !== "" &&
      data.tglakhir &&
      data.tglakhir !== ""
    ) {
      if (data.filtertype == 1) {
        params.issued_start =
          data.tglawal && data.tglawal !== ""
            ? moment(data.tglawal).format("YYYY-MM")
            : "";
        params.issued_end =
          data.tglakhir && data.tglakhir !== ""
            ? moment(data.tglakhir).format("YYYY-MM")
            : "";
      } else if (data.filtertype == 2) {
        params.paid_start =
          data.tglawal && data.tglawal !== ""
            ? moment(data.tglawal).format("YYYY-MM-DD")
            : "";
        params.paid_end =
          data.tglakhir && data.tglakhir !== ""
            ? moment(data.tglakhir).format("YYYY-MM-DD")
            : "";
      }
    }

    const result = await ApiGetRequest(`school/school-fee-invoice`, params);

    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal mengambil data riwayat pembayaran"
      });

      commit("changeBayarSpp", {
        loading: false
      });

      return null;
    } else {
      commit("changeBayarSpp", {
        riwayat_bayar: result.data.data,
        total:
          (result.data.meta && result.data.meta.total) ||
          result.data.data.length,
        loading: false
      });
    }
  },

  async getProductSpp({ commit }, payload = null) {
    commit("changeBayarSpp", {
      loading: true
    });

    if (payload) {
      await commit("changeBayarSpp", {
        page: payload || data.page
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: 200,
    };
    if(data.sortBy) params.sort_field = data.sortBy;
    if(data.sortType) params.sort_dir = data.sortType;
    if(data.sortType) params.sort_dir = data.sortType;
    if(data.filter) params.q = data.filter;
    if(data.filterClasses) params.classes = data.filterClasses;
    if(data.filterMajor) params.major = data.filterMajor;

    const result = await ApiGetRequest(`/school/school-fee-setting`, params);
    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal mengambil data riwayat pembayaran"
      });

      commit("changeBayarSpp", {
        loading: false
      });

      return null;
    } else {
      commit("changeBayarSpp", {
        product_spp: result.data.data || [],
        total: (result.data.meta && result.data.meta.total) || 0,
        loading: false
      });
    }
  },

  async getPenerimaDiskon({ commit }, payload = null) {
    commit("changeBayarSpp", {
      loading: true
    });

    if (payload) {
      await commit("changeBayarSpp", {
        page: payload || data.page
      });
    }

    const { data } = state;
    const params = {
      page: data.page,
      limit: data.limit,
      q: data.filter,
      sort_field: data.sortBy,
      sort_dir: data.sortType,
      classes: data.filterClasses || "",
      major: data.filterMajor || ""
    };

    const result = await ApiGetRequest(
      `/school/school-fee-setting/${data.detailId}`,
      params
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal mengambil data riwayat pembayaran"
      });

      commit("changeBayarSpp", {
        loading: false
      });

      return null;
    } else {
      commit("changeBayarSpp", {
        penerima_diskon: result.data.data || [],
        total: (result.data.meta && result.data.meta.total) || 0,
        loading: false
      });
    }
  },

  async setPenerimaDiskon({ commit, state }, payload = null) {
    commit("changeBayarSpp", {
      loading: true,
      error: false
    });

    const { data } = state;
    let result = await ApiPostRequest(
      `/school/school-fee-setting/${data.detailId || ""}`,
      payload.formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error || "Gagal setting diskon pelajar"
      });

      commit("changeBayarSpp", {
        loading: false,
        error: result.errorList || false
      });
      return false;
    } else {
      commit("changeBayarSpp", {
        loading: false
      });

      Message({
        type: "success",
        message: "Data Diskon pelajar berhasil diupdate"
      });

      return true;
    }
  },

  async createSpp({ commit, state }, payload = null) {
		if (state.data.loading){
			console.log("createSpp", DUPLICATE_CALL_SERVER_MESSAGE)
			return false
		}

    commit("changeBayarSpp", {
      loading: true,
      error: false
    });

    const result = await ApiPostRequest(
      `school/school-fee-invoice`,
      payload.formData
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error || "Gagal membuat data SPP"
      });

      commit("changeBayarSpp", {
        loading: false,
        error: result.errorList || false
      });
      return false;
    } else {
      commit("changeBayarSpp", {
        loading: false
      });

      Message({
        type: "success",
        message: "Data SPP baru berhasil ditambahkan"
      });

      return true;
    }
  },

  async createPengaturanSpp({ commit }, payload = null) {
    const result = await ApiPostRequest(
      `school/school-fee-setting`,
      payload.formData
    );

    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal menambahkan pengaturan SPP"
      });

      commit("changeBayarSpp", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Pengaturan SPP baru berhasil ditambahkan"
      });

      commit("changeBayarSpp", {
        error: false
      });

      return true;
    }
  },

  async updatePengaturanSpp({ commit, state }, payload = null) {
    const { data } = state;
    const result = await ApiPutRequest(
      `school/product/${data.idProductSpp || ""}/attach/${(payload &&
        payload.id) ||
        ""}`,
      payload.formData
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Gagal mengubah data pengaturan SPP"
      });

      commit("changeBayarSpp", {
        error: result.errorList ? result.errorList : false
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Pengaturan SPP berhasil diubah"
      });

      commit("changeBayarSpp", {
        error: false
      });

      return true;
    }
  },

  async deletePengaturanSpp({ state }, payload = null) {
    const { data } = state;
    const result = await ApiDeleteRequest(
      `school/product/${data.idProductSpp || ""}/attach/${(payload &&
        payload.id) ||
        ""}`
    );
    if (result.error) {
      Message({
        type: "error",
        message: result.error
          ? result.error
          : "Data Pengaturan SPP Gagal dihapus"
      });

      return false;
    } else {
      Message({
        type: "success",
        message: "Data Pengaturan SPP berhasil dihapus"
      });

      return true;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
