import repository from "@/repositories/DanaPartisipasiRepository"
import StrukturErrorService from "@/services/StrukturErrorService"
//import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers"

export default class DanaPartisipasiService {
	constructor() {
		this.dataRepository = repository
	}

	async detailDanaPartisipasiStudent(schoolUuid) {
		if (!schoolUuid) {
			throw new Error("School UUID required")
		}

		try {
			return await this.dataRepository.getStudentInSchoolAll(schoolUuid)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

	async listBillGet() {
		try {
			const params = {}
			return await this.dataRepository.getListBill(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

	async detailBillGet(id) {
		if (!id || typeof id !== 'string') {
			throw new Error("ID required")
		}

		try {
			return await this.dataRepository.getDetailBill(id)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

	async billByUserUuidGet(payload) {
		const {userUuids} = payload
		if (typeof userUuids !== 'object') {
			throw new Error("userUuids must array")
		}

		const params = {
			student_ids: userUuids
		}

		try {
			return await this.dataRepository.getBillByUserUuid(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

	async detailBillHistoryGet(id) {
		if (typeof id !== 'string') {
			throw new Error("ID required")
		}

		try {
			return await this.dataRepository.getDetailBillHistory(id)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

	async billActivationPost(id, payload) {
		if (typeof id !== 'object') {
			throw new Error("ID required")
		}

		const {isActive} = payload

		try {
			const formData = {
				is_active: isActive
			}

			return await this.dataRepository.postBillActivation(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

	async createBillPost(payload) {
		const {
			bill_name,
			is_active,
			expired_date,
			list,
		} = payload;

		// Validasi untuk bill_name
		if (typeof bill_name !== 'string' || bill_name.trim() === '') {
			throw new Error("bill_name must be a non-empty string");
		}

		// Validasi untuk is_active
		if (typeof is_active !== 'boolean') {
			throw new Error("is_active must be a boolean");
		}

		// Validasi untuk expired_date
		if (!/^\d{4}-\d{2}-\d{2}$/.test(expired_date)) {
			throw new Error("expired_date must be in the format YYYY-MM-DD");
		}

		// Validasi untuk list
		if (!Array.isArray(list) || list.length === 0) {
			throw new Error("list must be a non-empty array");
		}

		for (const item of list) {
			if (typeof item.wallet_id !== 'string' || item.wallet_id.trim() === '') {
				throw new Error("Each list item must have a non-empty string wallet_id");
			}
			if (typeof item.target_nominal === 'undefined') {
				throw new Error("Each list item must is required as target_nominal");
			}
		}

		try {
			const formData = {
				bill_name: bill_name,
				is_active: is_active,
				expired_date: expired_date+"T00:00:00+07:00",
				list: list.map((item) => ({
					wallet_id: item.wallet_id,
					target_nominal: parseInt(item.target_nominal.toString().replace(/\./g, ''), 10),
				})),
			};

			return await this.dataRepository.postCreateBill(formData);
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async updateBillPost(payload) {

		const {
			bill_name,
			is_active,
			expired_date,
			list,
			bill_id,
		} = payload

		// Validasi untuk bill_id
		if (typeof bill_id !== 'string' || bill_id.trim() === '') {
			throw new Error("bill_id must be a non-empty string");
		}

		// Validasi untuk bill_name
		if (typeof bill_name !== 'string' || bill_name.trim() === '') {
			throw new Error("bill_name must be a non-empty string");
		}

		// Validasi untuk is_active
		if (typeof is_active !== 'boolean') {
			throw new Error("is_active must be a boolean");
		}

		// Validasi untuk expired_date
		if (!/^\d{4}-\d{2}-\d{2}$/.test(expired_date)) {
			throw new Error("expired_date must be in the format YYYY-MM-DD");
		}

		// Validasi untuk list
		if (!Array.isArray(list) || list.length === 0) {
			throw new Error("list must be a non-empty array");
		}

		for (const item of list) {
			if (typeof item.wallet_id !== 'string' || item.wallet_id.trim() === '') {
				throw new Error("Each list item must have a non-empty string wallet_id");
			}
			if (typeof item.target_nominal === 'undefined') {
				throw new Error("Each list item must is required as target_nominal");
			}
		}

		try {
			const formData = {
				bill_id: bill_id,
				bill_name: bill_name,
				is_active: is_active,
				expired_date: expired_date,
				list: list.map((item) => ({
					wallet_id: item.wallet_id,
					target_nominal: parseInt(item.target_nominal.toString().replace(/\./g, ''), 10),
				})),
			}

			return await this.dataRepository.postCreateBill(formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

	async appInProductCheckGet() {
		try {
			return await this.dataRepository.getAppInProductCheck()
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

	async appInProductPost(id, payload) {
		const {formData} = payload

		try {
			return await this.dataRepository.postAppInProduct(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			//console.log(parsedError)
			throw parsedError
		}
	}

}
