import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import PembayaranSppService from "@/services/PembayaranSppService";
import moment from "moment";
import {NO_INTERNET_MESSAGE} from "@/services/messageService";
import {checkVw} from "@/services/internalService";

const state = {
	data: {
		showModalTunggakan: false,
		showModalSelectMonth: false,
		loading: false,
		filterClasses: "",
		filtertype: "1",
		filterQ: null,
		filterYearMonth: null,
		filterRangePaidAt: [],
		filterTglAwal: null,
		filterTglAkhir: null,
		dataPrint: false,
		itemsMessageTable: null,
		errorDataItems: null,
		items_invoice: [],
		limit: 10,
		page: 1,
		total: 0,
		items: [],
		sortField: "",
		sortDir: "",
		monitoringPembayaran: {
			loading: false,
			spp_dibayar: [],
			items: [],
			month: [],
			total: 0,
			filterClassId: null,
			errorDataItems: null,
		},
		columns: [
			{
				prop: "paid_at",
				sortable: false,
				label: "Tgl Bayar",
				width: "150",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "name",
				label: "Nama",
				sortable: false,
				minWidth: "150",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "classes",
				label: "Kelas",
				width: "200",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "month",
				label: "Periode",
				width: "180",
				sortable: false,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "total_fee",
				label: "Terbayar",
				width: "180",
				"class-name": "body-14 col-black-2"
			},
			{
				fixed: "right",
				label: "Aksi",
				width: checkVw(90),
				"class-name": "body-14 col-black-2"
			}
		],
		rekapPembayaran: {
			serverError: false,
			messageError: null
		}
	}
};

const mutations = {
	changeBayarSppDibayar(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeBayarSppDibayarMonitoring(state, payload) {
		state.data.monitoringPembayaran = Object.assign({}, state.data.monitoringPembayaran, payload);
	},
	changeBayarSppDibayarLoading(state, payload) {
		state.data.loading = payload
	},
	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
	setMonitoringItemsError(state, payload) {
		state.data.monitoringPembayaran.errorDataItems = payload
	},
};

const actions = {

	async onShowModalSelectMonth({commit}, isOpen) {
		commit("changeBayarSppDibayar", {showModalSelectMonth: isOpen})
	},

	async fetchStudentFeeInvoice({commit, state}) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppDibayarLoading", true)

		const params = {
			filterQ: data.filterQ,
			page: data.page,
			limit: data.limit,
			sort_field: "paid_at",
			sort_dir: "desc",
			filterStatus: "paid",
			filtertype: 2,
			paid_start: data.filterTglAwal,
			paid_end: data.filterTglAkhir
		};

		if (data.filterClasses) {
			params.filterClasses = data.filterClasses;
		}

		try {
			const service = new PembayaranSppService()
			const result = await service.sppStudentFeeInvoiceGet(params)

			if (result.data?.data) {
				if (!result.data.data.length) {
					let m = data.filterQ ? "Pencarian Tidak Ditemukan" : "Belum terdapat Pembayaran"
					commit("setMessageItemsTable", m)
				} else {
					commit("setMessageItemsTable", null)
				}

				commit("changeBayarSppDibayar", {
					items: result.data.data,
					total: (result.data.meta && result.data.meta.total) || result.data.data.length,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {

			commit("changeBayarSppDibayar", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("setDataItemsError", NO_INTERNET_MESSAGE)
				commit("setMessageItemsTable", NO_INTERNET_MESSAGE)
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeBayarSppDibayarLoading", false)
		}
	},

	async fetchRecapStudentFeeInvoice({commit, state}) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppDibayarLoading", true)

		const params = {
			filterQ: data.filterQ,
			sort_field: "paid_at",
			sort_dir: "desc",
			filterStatus: "paid",
		};

		if (data.filtertype === "1") {
			params.issued_start = data.filterYearMonth;
			params.issued_end = data.filterYearMonth;
		} else {
			params.paid_start = data.filterRangePaidAt[0];
			params.paid_end = data.filterRangePaidAt[1];
		}

		if (data.filterClasses) {
			params.filterClasses = data.filterClasses;
		}

		try {
			const service = new PembayaranSppService()
			const result = await service.sppStudentFeeInvoiceGet(params)

			if (result.data?.data) {
				if (!result.data.data.length) {
					let m = data.filterQ ? "Pencarian Tidak Ditemukan" : "Belum terdapat Pembayaran"
					commit("setMessageItemsTable", m)
				} else {
					commit("setMessageItemsTable", null)
				}

				commit("changeBayarSppDibayar", {
					items: result.data.data,
					total: (result.data.meta && result.data.meta.total) || result.data.data.length,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {

			commit("changeBayarSppDibayar", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("setDataItemsError", NO_INTERNET_MESSAGE)
				commit("setMessageItemsTable", NO_INTERNET_MESSAGE)
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeBayarSppDibayarLoading", false)
		}
	},

	async fetchMonitoringStudentFeeInvoice({commit, state}) {

		const {monitoringPembayaran} = state.data

		const params = {
			filterStatus: "paid",
		};

		params.issued_start = monitoringPembayaran.month[0];
		params.issued_end = monitoringPembayaran.month[1];

		if (monitoringPembayaran.filterClassId) {
			params.filterClasses = monitoringPembayaran.filterClassId;
		}

		try {
			const service = new PembayaranSppService()
			const result = await service.sppStudentFeeInvoiceGet(params)

			if (result.data?.data) {
				commit("changeBayarSppDibayarMonitoring", {
					spp_dibayar: result.data.data,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {

			commit("changeBayarSppDibayarMonitoring", {
				spp_dibayar: [],
			})

			return false;
		}
	},

	async fetchListStudentFeeInvoiceByClass({commit, state}, months) {

		if (typeof months !== "object") {
			console.log("bulan harus array")
			return
		}

		const {monitoringPembayaran} = state.data

		if (monitoringPembayaran.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppDibayarMonitoring", {loading: true})

		const params = {
			months: months,
			class_id: monitoringPembayaran.filterClassId,
		};

		try {
			const service = new PembayaranSppService()
			const result = await service.listStudentFeeInvoiceGet(params)

			if (result.data?.data) {
				commit("changeBayarSppDibayarMonitoring", {
					items: result.data.data,
					total: (result.data.meta && result.data.meta.total) || result.data.data.length,
				})
			}

			commit("setMonitoringItemsError", null)

			return true
		} catch (e) {

			commit("changeBayarSppDibayarMonitoring", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setMonitoringItemsError", SERVER_ERROR_MESSAGE)
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("setMonitoringItemsError", NO_INTERNET_MESSAGE)
				return false
			}

			let m = e.error ? e.error : "Gagal mengambil data Rombel"
			Message({
				type: "error",
				message: m
			});

			commit("setMonitoringItemsError", m)
			return false;
		} finally {
			commit("changeBayarSppDibayarMonitoring", {loading: false})
		}
	},

};

const getters = {

	dataMonthsComputed: (state) => {
		if (!state.data.monitoringPembayaran.month) {
			return [];
		}

		const startDate = moment(state.data.monitoringPembayaran.month[0]);
		const endDate = moment(state.data.monitoringPembayaran.month[1]);

		//const duration = moment.duration(endDate.diff(startDate));
		const months = endDate.diff(startDate, "months");
		const monthArray = [];
		for (let i = 0; i <= months; i++) {
			const currentMonth = startDate.clone().add(i, "months");
			const label = currentMonth.format("MMM YY");
			monthArray.push({
				date: currentMonth.format("YYYY-MM"),
				label: label,
				issued_at: currentMonth.format("YYYY-MM") + `-01 00:00:00`
			});
		}
		return monthArray;
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
