import {checkVw} from "@/services/internalService";

const state = {
	data: {
		filterQ: null,
		filterMajor: null,
		filterStatus: null,
		filterWaveNumber: null,
		filterDateRange: null,
		filterAcademicYear: null,
		columns: [
			{
				prop: "file",
				label: "File",
				minWidth: 200,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "status",
				label: "Status",
				width: 140,
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "created_at",
				label: "Tanggal",
				width: 180,
				"class-name": "body-14 col-black-2",
			},
			{
				fixed: "right",
				prop: "action",
				label: "Aksi",
				width: checkVw(100),
				"class-name": "body-14 col-black-2",
			},
		],
	},
}

const mutations = {
	changePpdbPendaftar(state, payload) {
		state.data = Object.assign({}, state.data, payload)
	},
}

const actions = {}

const getters = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
