import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import PembayaranSppService from "@/services/PembayaranSppService";
import moment from "moment";
import Vue from "vue";
import {NO_INTERNET_MESSAGE} from "@/services/messageService";

const state = {
	data: {
		showModalPengaturan: false,
		showModalSelectMonth: false,
		loading: false,
		filterStatus: "active",
		filterGrade: "",
		filterMajor: "",
		filterClasses: "",
		filtertype: "1",
		filterQ: null,
		filterYearMonth: moment().format("YYYY-MM"),
		dataPrint: false,
		itemsMessageTable: null,
		errorDataItems: null,
		errorformData: null,
		limit: 50,
		page: 1,
		total: 0,
		items: [],
		sortField: "name",
		sortDir: "asc",
		riwayat_bayar: [],
		spp_baku: [],
		columns: [
			{
				prop: "name",
				label: "(Tingkat) Kelas",
				minWidth: "140",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "major",
				label: "Jurusan",
				width: "180",
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "school_fee",
				label: "Biaya SPP",
				width: "140",
				sortable: true,
				"class-name": "body-14 col-black-2"
			},
			{
				prop: "student_discount_receiver",
				label: "Pengaturan Diskon",
				width: "200",
				"class-name": "body-14 col-black-2"
			}
		],
		dataDiskon: {
			loading: false,
			serverError: false,
			messageError: null,
			itemsMessageTable: null,
			errorDataItems: null,
			filterClasses: '',
			filterMajor: '',
			error: null,
			filterQ: null,
			classIdNotFound: false,
			limit: 50,
			page: 1,
			total: 0,
			itemsDataOld: [],
			items: [],
			penerima_diskon: [],
			columns: [
				{
					prop: "name",
					label: "Nama / NISN",
					minWidth: "200",
					sortable: true,
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "classes",
					label: "Kelas",
					minWidth: "180",
					sortable: true,
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "fee",
					label: "Biaya SPP",
					width: "130",
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "discount_amount",
					label: "Diskon",
					width: "160",
					"class-name": "body-14 col-black-2"
				},
				{
					prop: "pembayaran",
					label: "Pembayaran",
					width: "140",
					"class-name": "body-14 col-black-2"
				}
			]
		}
	}
};

const mutations = {
	setUpdateDataItems(state, payload) {
		payload.forEach(itemBaru => {
			const index = state.data.items.findIndex(itemLama => itemLama.id === itemBaru.id);
			if (index !== -1) {
				// Menggunakan Vue.set untuk memastikan reactivity
				Vue.set(state.data.items, index, itemBaru);
			}
		});
	},
	changeBayarSppPengaturan(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeBayarSppPengaturanLoading(state, payload) {
		state.data.loading = payload
	},
	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},
	changeBayarSppPengaturanDataDiskon(state, payload) {
		state.data.dataDiskon = Object.assign({}, state.data.dataDiskon, payload);
	},
	changeBayarSppPengaturanDataDiskonLoading(state, payload) {
		state.data.dataDiskon.loading = payload
	},
};

const actions = {

	onShowModal({commit}, isOpen) {
		commit("changeBayarSppPengaturan", {showModalPengaturan: isOpen})
	},

	async fetchListPelajarDiscountByClassId({commit, state}, classId) {

		const {dataDiskon} = state.data
		if (!classId) {
			Message({
				type: "info",
				message: "ID Not Found"
			})
			return
		}

		if (dataDiskon.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppPengaturanDataDiskonLoading", true)

		const params = {
			page: 1,
			limit: 100,
			filterQ: dataDiskon.filterQ,
			filterClasses: dataDiskon.filterClasses,
			filterMajor: dataDiskon.filterMajor,
		};

		try {
			const service = new PembayaranSppService()
			const result = await service.listTagihanSppPengaturanByIdGet(classId, params)

			if (result.data?.data) {
				if (!result.data.data.length) {
					let m = dataDiskon.filterQ ? "Pencarian Tidak Ditemukan" : "Data masih Kosong"
					commit("changeBayarSppPengaturanDataDiskon", {
						itemsMessageTable: m,
					})
				} else {
					commit("changeBayarSppPengaturanDataDiskon", {
						itemsMessageTable: null,
					})
				}

				const resultSort = result.data.data.sort((a, b) => a.student.user.name.localeCompare(b.student.user.name));

				commit("changeBayarSppPengaturanDataDiskon", {
					items: resultSort,
					itemsDataOld: JSON.parse(JSON.stringify(resultSort)),
				})
			}

			commit("changeBayarSppPengaturanDataDiskon", {
				classIdNotFound: false,
				itemsMessageTable: null,
			})

			return true
		} catch (e) {
			console.log(e)

			commit("changeBayarSppPengaturanDataDiskon", {
				classIdNotFound: false,
				itemsDataOld: [],
				items: [],
			})

			if (e.status >= 500) {
				commit("changeBayarSppPengaturanDataDiskon", {
					itemsMessageTable: SERVER_ERROR_MESSAGE,
					errorDataItems: SERVER_ERROR_MESSAGE,
				})
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("changeBayarSppPengaturanDataDiskon", {
					itemsMessageTable: NO_INTERNET_MESSAGE,
					errorDataItems: NO_INTERNET_MESSAGE,
				})
				return false
			}else if (e.errorList[404]) {
				commit("changeBayarSppPengaturanDataDiskon", {
					classIdNotFound: true,
				})
			}

			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			return false;
		} finally {
			commit("changeBayarSppPengaturanDataDiskonLoading", false)
		}
	},

	async postPelajarDiscountByClassId({commit, state}, payload) {

		const {dataDiskon} = state.data
		const {classId, formData} = payload

		if (dataDiskon.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppPengaturanDataDiskonLoading", true)

		try {
			const service = new PembayaranSppService()
			await service.updateTagihanSppPengaturanPost(classId, formData)

			Message({
				type: "success",
				message: "Diskon SPP untuk Pelajar, Berhasil"
			});

			return true
		} catch (e) {

			if (e.status >= 500) {
				Message({
					type: "error",
					message: SERVER_ERROR_MESSAGE
				});
				return false
			} else if (e.code === "ERR_NETWORK") {
				Message({
					type: "error",
					message: NO_INTERNET_MESSAGE
				});
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			return false;
		} finally {
			commit("changeBayarSppPengaturanDataDiskonLoading", false)
		}
	},

	async fetchListTagihanSpp({commit, state}) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppPengaturanLoading", true)

		const params = {
			page: data.page,
			limit: data.limit,
			filterQ: data.filterQ,
			sort_field: data.sortField,
			sort_dir: data.sortDir
		};

		if (data.filterClasses) {
			params.filterClasses = data.filterClasses;
		}

		if (data.filterMajor) {
			params.filterMajor = data.filterMajor;
		}

		try {
			const service = new PembayaranSppService()
			const result = await service.tagihanSppPengaturanGet(params)

			if (result.data?.data) {
				if (!result.data.data.length) {
					let m = data.filterQ ? "Pencarian Tidak Ditemukan" : "Data masih Kosong"
					commit("setMessageItemsTable", m)
				} else {
					commit("setMessageItemsTable", null)
				}

				const resultSort = result.data.data
					.sort((a, b) => {
						// First, sort by 'grade' in ascending order
						if (a.grade !== b.grade) {
							return a.grade - b.grade; // Ascending order for grade
						}
						// If grades are equal, sort by 'name' in ascending order
						return a.name.localeCompare(b.name); // Ascending order for name
					});

				commit("changeBayarSppPengaturan", {
					items: resultSort,
					total: (result.data.meta && result.data.meta.total) || result.data.data.length,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {

			commit("changeBayarSppPengaturan", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("setDataItemsError", NO_INTERNET_MESSAGE)
				commit("setMessageItemsTable", NO_INTERNET_MESSAGE)
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeBayarSppPengaturanLoading", false)
		}
	},

	async postPengaturanTagihanSpp({commit, state, dispatch}, formData) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeBayarSppPengaturanLoading", true)

		try {
			const service = new PembayaranSppService()
			const result = await service.createAndUpdateTagihanSppPengaturanPost(formData)

			if (result.data?.data.length) {
				commit("setUpdateDataItems", result.data.data)
			} else {
				commit("changeBayarSppPengaturanLoading", false)
				dispatch("fetchListTagihanSpp")
			}
			Message({
				type: "success",
				message: "Atur SPP Baku, Berhasil"
			});

			return true
		} catch (e) {

			commit("changeBayarSppPengaturan", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				Message({
					type: "error",
					message: SERVER_ERROR_MESSAGE
				});
				return false
			} else if (e.code === "ERR_NETWORK") {
				Message({
					type: "error",
					message: NO_INTERNET_MESSAGE
				});
				return false
			}
			let m = e.error ? e.error : "Gagal mengambil data tunggakan"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeBayarSppPengaturanLoading", false)
		}
	},

};

const getters = {

	itemsPengaturanGetter: state => {
		const {filterGrade, items} = state.data
		if (filterGrade) {
			return items.filter(item => item.grade === parseInt(filterGrade));
		}
		return items
	},

	itemsListPelajarDiskonGetter: state => {
		const {filterQ, items} = state.data.dataDiskon
		if (filterQ) {
			return items.filter(item => {
			const nameLower = item?.student?.user?.name || ''
			const nisNikLower = item?.student?.user?.nis_nik || ''
				return nameLower.toLowerCase().includes(filterQ.toLowerCase()) || nisNikLower.toLowerCase().includes(filterQ.toLowerCase())
			});
		}
		return items
	}

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
