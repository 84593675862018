import {requirePermission} from "@/router/index";
import WebPpdb from "@/router/WebPpdb";

export default [
	{
		path: "/website",
		name: "produkWebsiteSekolah",
		component: () =>
			import(/* webpackChunkName: "website" */ "../views/WebSchool"),
		meta: {
			title: "Klasite | ",
			allowedRole: [
				"Head Master",
				"Ppdb Comittee",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
			],
		},
		beforeEnter: requirePermission,
		redirect: "website/domain-tema",
		children: [
			...WebPpdb,
			{
				path: "domain-tema",
				name: "DomainTema",
				component: () =>
					import(
						/* webpackChunkName: "website-domain-tema" */
						"../views/WebSchool/DomainTema"
						),
				meta: {
					title: "Domain Tema | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "konten-website",
				name: "KlasiteKontenWebsite",
				redirect: "/website/konten-website/beranda",
				component: () =>
					import(
						/* webpackChunkName: "website-konten-website" */
						"../views/WebSchool/KontenWebsite"
						),
				meta: {
					title: "Klasite | ",
					allowedRole: ["all"],
				},
				children: [
					{
						path: "beranda",
						name: "KontenWebsiteBeranda",
						component: () =>
							import(
								/* webpackChunkName: "beranda-website" */
								"../views/WebSchool/KontenWebsite/Beranda"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "profil",
						name: "KontenWebsiteProfil",
						component: () =>
							import(
								/* webpackChunkName: "profil-website" */
								"../views/WebSchool/KontenWebsite/Profil"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "berita",
						name: "KontenWebsiteBerita",
						component: () =>
							import(
								/* webpackChunkName: "berita-website" */
								"../views/WebSchool/KontenWebsite/Berita"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "pengumuman",
						name: "KontenWebsitePengumuman",
						component: () =>
							import(
								/* webpackChunkName: "pengumuman-website" */
								"../views/WebSchool/KontenWebsite/Pengumuman"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},

					{
						path: "ppdb",
						name: "KontenWebsitePpdb",
						component: () =>
							import(
								/* webpackChunkName: "ppdb-website" */
								"../views/WebSchool/KontenWebsite/Ppdb"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},

					{
						path: "biaya-sekolah",
						name: "KontenWebsiteSchoolPaid",
						component: () =>
							import(
								/* webpackChunkName: "school-paid-website" */
								"../views/WebSchool/KontenWebsite/SchoolPaid"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},

					{
						path: "galeri",
						name: "KontenWebsiteGaleri",
						component: () =>
							import(
								/* webpackChunkName: "galeri-website" */
								"../views/WebSchool/KontenWebsite/Galeri"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "jurusan",
						name: "KontenWebsiteJurusan",
						component: () =>
							import(
								/* webpackChunkName: "jurusan-website" */
								"../views/WebSchool/KontenWebsite/Jurusan"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "eks-kurikuler",
						name: "KontenWebsiteEksKul",
						component: () =>
							import(
								/* webpackChunkName: "ekskul-website" */
								"../views/WebSchool/KontenWebsite/EksKulikuler"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "lsp-dan-manajemen-mutu",
						name: "KontenWebsiteLspManajemenMutu",
						component: () =>
							import(
								/* webpackChunkName: "lsp-mm-website" */
								"../views/WebSchool/KontenWebsite/LspManajemenMutu"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "bkk",
						name: "KontenWebsiteBkk",
						component: () =>
							import(
								/* webpackChunkName: "bkk-website" */
								"../views/WebSchool/KontenWebsite/Bkk"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "bkk-company-request",
						name: "KontenWebsiteBkkCompanyRequest",
						component: () =>
							import(
								/* webpackChunkName: "bkk-company-website" */
								"../views/WebSchool/KontenWebsite/Bkk/CompanyRequest"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "kontak",
						name: "KontenWebsiteKontak",
						component: () =>
							import(
								/* webpackChunkName: "kontak-website" */
								"../views/WebSchool/KontenWebsite/Kontak"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
					{
						path: "seo-google",
						name: "KontenWebsiteSeoGoogle",
						component: () =>
							import(
								/* webpackChunkName: "seo-google" */
								"../views/WebSchool/KontenWebsite/SeoGoogle"
								),
						meta: {
							title: "Konten Website | ",
							allowedRole: ["all"],
						},
						beforeEnter: requirePermission,
					},
				],
			},
		],
	},
]
