import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import {_} from "vue-underscore";
import moment from "moment";
//import {isSchoolProductsLocked} from "@/services/productService";
import KeuanganSekolah from "@/router/KeuanganSekolah";
import PembayaranSpp from "@/router/PembayaranSpp";
import DanaPartisipasi from "@/router/DanaPartisipasi";
import Koperasi from "@/router/Koperasi";
import KantinDigital from "@/router/KantinDigital";
import WebSchool from "@/router/WebSchool";
import ControlKiosk from "@/router/ControlKiosk";
import Konseling from "@/router/Konseling";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Dashboard",
		redirect: `/${process.env.VUE_APP_PRODUCT}/statistik`,
		component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
		meta: {
			title: "Dashboard | ",
			allowedRole: ["all"],
		},
	},
	// page public
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/Login"),
		meta: {
			title: "Login | ",
		},
	},
	{
		path: "/kebijakan-privasi",
		name: "KebijakanPrivasi",
		component: () =>
			import(
				/* webpackChunkName: "kebijakan-privasi" */
				"../views/KebijakanPrivasi"
				),
		meta: {
			title: "Forgot Password | ",
		},
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: () =>
			import(
				/* webpackChunkName: "forgot-pwd" */
				"../views/ForgotPassword"
				),
		meta: {
			title: "Forgot Password | ",
		},
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: () =>
			import(
				/* webpackChunkName: "reset-pwd" */
				"../views/ResetPassword"
				),
		meta: {
			title: "Reset Password | ",
		},
	},

	// page admin-panel
	{
		path: `/${process.env.VUE_APP_PRODUCT}`,
		name: "DashboardApp",
		redirect: `/${process.env.VUE_APP_PRODUCT}/statistik`, // default call first page
		component: () =>
			import(/* webpackChunkName: "beranda" */ "../views/Beranda"),
		meta: {
			title: "Beranda | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance",
				"Teacher",
			],
		},
		children: [
			{
				path: `/${process.env.VUE_APP_PRODUCT}/statistik`,
				name: "BerandaStatistik",
				component: () =>
					import(
						/* webpackChunkName: "statistik" */
						"../views/Beranda/Statistik"
						),
				meta: {
					title: "Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
						"Teacher",
						"Counseling",
					],
				},
			},

			{
				path: `/${process.env.VUE_APP_PRODUCT}/pembelian`,
				name: "BerandaPembelian",
				component: () =>
					import(
						/* webpackChunkName: "pembelian" */
						"../views/Beranda/Pembelian"
						),
				meta: {
					title: "Pembelian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
		],
	},

	{
		path: "/profile",
		name: "Profile",
		component: () =>
			import(/* webpackChunkName: "profile" */ "../views/Profile"),
		meta: {
			title: "Profile | ",
			allowedRole: ["all"],
		},
		redirect: "/profile/my-profile",
		children: [
			{
				path: "my-profile",
				name: "MyProfile",
				component: () =>
					import(
						/* webpackChunkName: "profile-myprofile" */ "../views/Profile/MyProfile"
						),
				meta: {
					title: "Profile | ",
					allowedRole: ["all"],
				},
			},
			{
				path: "my-role",
				name: "MyRole",
				component: () =>
					import(
						/* webpackChunkName: "profile-myrole" */ "../views/Profile/MyRole"
						),
				meta: {
					title: "Profile | ",
					allowedRole: ["all"],
				},
			},
			{
				path: "my-password",
				name: "MyPassword",
				component: () =>
					import(
						/* webpackChunkName: "profile-myrole" */ "../views/Profile/MyPassword"
						),
				meta: {
					title: "Profile | ",
					allowedRole: ["all"],
				},
			},
		],
	},
	{
		path: "/pengguna",
		name: "Pengguna",
		redirect: "/pengguna/pelajar", // default call first page
		component: () =>
			import(/* webpackChunkName: "pengguna" */ "../views/Pengguna"),
		meta: {
			title: "Pengguna | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
			],
		},
		children: [
			{
				path: "pelajar",
				name: "Pelajar",
				component: () =>
					import(
						/* webpackChunkName: "pelajar" */
						"../views/Pengguna/Pelajar"
						),
				meta: {
					title: "Peserta Didik | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "create-guru-staff",
				name: "CreateGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "formgurustaff" */
						"../views/Pengguna/GuruStaff/FormGuruStaff"
						),
				meta: {
					title: "Buat Guru Staff | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "edit-guru-staff/:id",
				name: "EditGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "formgurustaff" */
						"../views/Pengguna/GuruStaff/FormGuruStaff"
						),
				meta: {
					title: "Ubah Guru Staff | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "detail-guru-staff/:id",
				name: "DetailGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "formgurustaff" */
						"../views/Pengguna/FileStorageStaffGuru/index.vue"
						),
				meta: {
					title: "Detail Guru Staff | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "import-excel-guru-staff",
				name: "ImportExcelGuru",
				component: () =>
					import(
						/* webpackChunkName: "importexcelguru" */
						"../views/Pengguna/GuruStaff/ImportExcel/preview.vue"
						),
				meta: {
					title: "Import Excel Guru | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "guru-staff",
				name: "GuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "gurustaff" */
						"../views/Pengguna/GuruStaff"
						),
				meta: {
					title: "Guru Staff | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "edit-pelajar/:id",
				name: "EditPelajar",
				component: () =>
					import(
						/* webpackChunkName: "formpelajarNew" */
						"../views/Pengguna/Pelajar/FormPelajarNew"
						),
				meta: {
					title: "Ubah Peserta Didik | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "detail-pelajar/:id",
				name: "DetailPelajar",
				component: () =>
					import(
						/* webpackChunkName: "filestorage" */
						"../views/Pengguna/FileStorage"
						),
				meta: {
					title: "Detail Peserta Didik | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "import-excel-pelajar",
				name: "PreviewImportExcelPelajar",
				component: () =>
					import(
						/* webpackChunkName: "preview-importexcelpelajar" */
						"../views/Pengguna/Pelajar/ImportExcel/preview.vue"
						),
				meta: {
					title: "Import Excel Peserta Didik | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "import-excel-dapodik",
				name: "PreviewImportExcelDapodik",
				component: () =>
					import(
						/* webpackChunkName: "import-validasiExcelDapodik" */
						"../views/Pengguna/Pelajar/ImportExcel/validasiExcelDapodik.vue"
						),
				meta: {
					title: "Import Excel Peserta Didik | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "import-excel-dapodik/riwayat",
				name: "RiwayatImportExcelDapodik",
				component: () =>
					import(
						/* webpackChunkName: "import-listImportDapodik" */
						"../views/Pengguna/Pelajar/ImportExcel/listImportDapodik.vue"
						),
				meta: {
					title: "Riwayat Import Excel Peserta Didik Dapodik |  ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "registrasi-via-sso",
				name: "RegistrationViaSso",
				component: () =>
					import(
						/* webpackChunkName: "tamu-view" */
						"../views/Pengguna/Tamu"
						),
				meta: {
					title: "Registrasi via SSO Google | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pengajuan-data-user/student",
				name: "RequestUserDataStudent",
				component: () =>
					import(
						/* webpackChunkName: "request-user-data" */
						"../views/Pengguna/RequestUserData"
						),
				meta: {
					title: "Pengajuan Data | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pengajuan-data-user/teacher",
				name: "RequestUserDataTeacher",
				component: () =>
					import(
						/* webpackChunkName: "request-user-data" */
						"../views/Pengguna/RequestUserData"
						),
				meta: {
					title: "Pengajuan Data | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "files",
				name: "SearchFileStorage",
				component: () =>
					import(
						/* webpackChunkName: "pengguna-search-storage" */
						"../views/Pengguna/SearchFileStorage"
						),
				meta: {
					title: "Pencarian Dokumen | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "files/bin",
				name: "BinFileStorage",
				component: () =>
					import(
						/* webpackChunkName: "pengguna-bin-storage" */
						"../views/Pengguna/BinFileStorage"
						),
				meta: {
					title: "Pencarian Dokumen | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "files/upload",
				name: "UploadDokumenFileStorage",
				component: () =>
					import(
						/* webpackChunkName: "pengguna-upload-storage" */
						"../views/Pengguna/UploadDokumen"
						),
				meta: {
					title: "Upload Dokumen | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "files/upload/kelas",
				name: "UploadDokumenFotoKelasFileStorage",
				component: () =>
					import(
						/* webpackChunkName: "pengguna-upload-storage" */
						"../views/Pengguna/UploadDokumenPerKelas"
						),
				meta: {
					title: "Upload Dokumen | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pelajar/upload/foto",
				name: "UploadFotoProfilePelajar",
				component: () =>
					import(
						/* webpackChunkName: "pengguna-upload-storage" */
						"../views/Pengguna/UploadDokumenFoto"
						),
				meta: {
					title: "Update Foto Profil Peserta Didik | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "validasi",
				name: "ValidasiData",
				component: () =>
					import(
						/* webpackChunkName: "pelajar" */
						"../views/Pengguna/ValidasiData"
						),
				meta: {
					title: "Validasi Data Pengguna | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ortu-wali",
				name: "OrtuWali",
				component: () =>
					import(
						/* webpackChunkName: "ortuwali" */
						"../views/Pengguna/OrtuWali"
						),
				meta: {
					title: "Ortu Wali | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
		],
	},
	{
		path: "/kts",
		name: "PenggunaaKts",
		redirect: "/kts/pelajar", // default call first page
		component: () =>
			import(/* webpackChunkName: "pengguna" */ "../views/Pengguna"),
		meta: {
			title: "Pengguna | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
			],
		},
		children: [
			{
				path: "kartu-pelajar",
				name: "KartuPelajar",
				component: () =>
					import(
						/* webpackChunkName: "kartu-pelajar" */
						"../views/Pengguna/KartuPelajar"
						),
				meta: {
					title: "Kartu Peserta Didik | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "create-pelajar",
				name: "CreatePelajar",
				component: () =>
					import(
						/* webpackChunkName: "formpelajar" */
						"../views/Pengguna/Pelajar/FormPelajarNew"
						),
				meta: {
					title: "Buat Peserta Didik | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "edit-kartu-pelajar/:id",
				name: "EditKartuPelajar",
				component: () =>
					import(
						/* webpackChunkName: "edit-kartu-pelajar" */
						"../views/Pengguna/KartuPelajar/FormPelajar"
						),
				meta: {
					title: "Ubah Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pengaturan-kartu-pelajar",
				name: "PengaturanKartuPelajar",
				component: () =>
					import(
						/* webpackChunkName: "pengaturan-kartu-pelajar" */
						"../views/Pengguna/KartuPelajar/Pengaturan"
						),
				meta: {
					title: "Kartu Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
		],
	},
	{
		path: "/sekolah",
		name: "Sekolah",
		redirect: "/sekolah/profile", // default call first page
		component: () =>
			import(/* webpackChunkName: "profilsekolah" */ "../views/Sekolah"),
		meta: {
			title: "Sekolah | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
			],
		},
		beforeEnter: requirePermission,
		children: [
			{
				path: "profile",
				name: "SekolahProfile",
				component: () =>
					import(
						/* webpackChunkName: "pengaturanjarak" */ "../views/Sekolah/Profile"
						),
				meta: {
					title: "Sekolah | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "verification-code",
				name: "CodeVerification",
				component: () =>
					import(
						/* webpackChunkName: "CodeVerification" */ "../views/Sekolah/VerificationCode"
						),
				meta: {
					title: "Sekolah | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "setting-koordinat",
				name: "SettingKoordinat",
				component: () =>
					import(
						/* webpackChunkName: "pengaturanjarak" */ "../views/Sekolah/PengaturanJarak"
						),
				meta: {
					title: "Sekolah | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "file",
				name: "SekolahDokumen",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumen" */ "../views/Sekolah/FileStorage"
						),
				meta: {
					title: "Dokumen Sekolah | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "file/bin",
				name: "SekolahDokumenBin",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenBin" */ "../views/Sekolah/FileStorage/BinFileStorage"
						),
				meta: {
					title: "Dokumen Sekolah | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},

			{
				path: "pkks",
				name: "SekolahDokumenPkks",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenPkks" */ "../views/Sekolah/Pkks"
						),
				meta: {
					title: "Dokumen PPKS | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pkks/bin",
				name: "SekolahDokumenBinPkks",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenBinPkks" */ "../views/Sekolah/Pkks/BinFileStorage"
						),
				meta: {
					title: "Dokumen PPKS Arsip | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},

			{
				path: "graduation",
				name: "SekolahDokumenGraduation",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenGraduation" */ "../views/Sekolah/Graduation"
						),
				meta: {
					title: "Kriteria Kelulusan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "graduation/bin",
				name: "SekolahDokumenBinGraduation",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenBinGraduation" */ "../views/Sekolah/Graduation/BinFileStorage"
						),
				meta: {
					title: "Kriteria Kelulusan Arsip | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},

			{
				path: "snpmb",
				name: "SekolahDokumenSNPMB",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenSNPMB" */ "../views/Sekolah/SNPMB"
						),
				meta: {
					title: "SNPMB | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "snpmb/bin",
				name: "SekolahDokumenBinSNPMB",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenBinSNPMB" */ "../views/Sekolah/SNPMB/BinFileStorage"
						),
				meta: {
					title: "SNPMB Arsip | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},

			{
				path: "inovasi",
				name: "SekolahDokumenInovasi",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenInovasi" */ "../views/Sekolah/Inovasi"
						),
				meta: {
					title: "Dokumen Inovasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "inovasi/bin",
				name: "SekolahDokumenBinInovasi",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenBinInovasi" */ "../views/Sekolah/Inovasi/BinFileStorage"
						),
				meta: {
					title: "Dokumen Inovasi Arsip | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "penghargaan-prestasi",
				name: "SekolahDokumenPenghargaanPrestasi",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenPenghargaanPrestasi" */ "../views/Sekolah/PenghargaanPrestasi"
						),
				meta: {
					title: "Dokumen Penghargaan Prestasi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "penghargaan-prestasi/bin",
				name: "SekolahDokumenBinPenghargaanPrestasi",
				component: () =>
					import(
						/* webpackChunkName: "SekolahDokumenBinPenghargaanPrestasi" */ "../views/Sekolah/PenghargaanPrestasi/BinFileStorage"
						),
				meta: {
					title: "Dokumen Penghargaan Prestasi Arsip | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pengaturan-hak-akses",
				name: "pengaturanHakAkses",
				component: () =>
					import(
						/* webpackChunkName: "pengaturan-hak-akses" */
						"../views/PengaturanHakAkses"
						),
				meta: {
					title: "Pengaturan Hak Akses | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
			},
		],
	},
	{
		path: "/agenda-surat",
		name: "AgendaSurat",
		redirect: "/agenda-surat/data", // default call first page
		component: () =>
			import(/* webpackChunkName: "agendaSurat" */ "../views/AgendaSurat"),
		meta: {
			title: "Agenda Surat | ",
			allowedRole: ["Head Master", "School Admin"],
		},
		beforeEnter: requirePermission,
		children: [
			{
				path: "data",
				name: "AgendaSuratData",
				component: () =>
					import(
						/* webpackChunkName: "agenda-surat-data" */ "../views/AgendaSurat/Data/index.vue"
						),
				meta: {
					title: "Agenda Surat | ",
					allowedRole: ["Head Master", "School Admin"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "surat-masuk",
				name: "AgendaSuratSuratMasuk",
				component: () =>
					import(
						/* webpackChunkName: "agenda-surat-surat-masuk" */ "../views/AgendaSurat/SuratMasuk"
						),
				meta: {
					title: "Agenda Surat | ",
					allowedRole: ["Head Master", "School Admin"],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "surat-masuk-sekolah",
				name: "AgendaSuratSuratMasukSekolah",
				component: () =>
					import(
						/* webpackChunkName: "agenda-surat-surat-masuk-sekolah" */ "../views/AgendaSurat/SuratMasukExternal"
						),
				meta: {
					title: "Surat Masuk Sekolah | ",
					allowedRole: ["Head Master", "School Admin"],
				},
				beforeEnter: requirePermission,
			},
		],
	},
	{
		path: "/pembelajaran",
		name: "Pembelajaran",
		redirect: "/pembelajaran/jurusan",
		component: () =>
			import(
				/* webpackChunkName: "pembelajaran-redirect" */
				"../views/Pembelajaran/"
				),
		meta: {
			title: "Pembelajaran | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
			],
		},
		children: [
			{
				path: "jurusan",
				name: "Jurusan",
				component: () => import("../views/Pembelajaran/Jurusan"),
				meta: {
					title: "Jurusan | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ruang-kelas",
				name: "RuangKelas",
				component: () => import("../views/Pembelajaran/RuangKelas"),
				meta: {
					title: "Ruang Kelas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ruang-kelas/data-kelas/:id",
				name: "RuangKelasDataKelas",
				component: () =>
					import("../views/Pembelajaran/RuangKelas/FormTambahSiswa"),
				meta: {
					title: "Tambah Siswa | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ruang-kelas/import-excel-pelajar/:id",
				name: "ImportExcelPelajar",
				component: () =>
					import(
						"../views/Pembelajaran/RuangKelas/FormTambahSiswa/ImportExcel/preview.vue"
						),
				meta: {
					title: "Import Excel Pelajar | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "mata-pelajaran",
				name: "MataPelajaran",
				component: () =>
					import(
						/* webpackChunkName: "matapelajaran" */
						"../views/Pembelajaran/Mapel"
						),
				meta: {
					title: "Mata Pelajaran | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "jadwal",
				name: "JadwalPelajaran",
				component: () =>
					import(
						/* webpackChunkName: "jadwal" */
						"../views/Pembelajaran/Jadwal"
						),
				meta: {
					title: "Jadwal | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "materi",
				name: "Materi",
				component: () =>
					import(
						/* webpackChunkName: "materi" */
						"../views/Pembelajaran/Materi"
						),
				meta: {
					title: "Materi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "tugas",
				name: "Tugas",
				component: () =>
					import(
						/* webpackChunkName: "tugas" */
						"../views/Pembelajaran/Tugas"
						),
				meta: {
					title: "Tugas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "tugas/penilaian/:id",
				name: "PenilaianTugas",
				component: () =>
					import(
						/* webpackChunkName: "penilaian tugas" */
						"../views/Pembelajaran/Tugas/HalamanPenilaian"
						),
				meta: {
					title: "Penilaian Tugas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "tugas/penilaian/:id_penilaian/detail-peserta/:id",
				name: "PenilaianTugasDetailPeserta",
				component: () =>
					import(
						/* webpackChunkName: "penilaian tugas detail siswa" */
						"../views/Pembelajaran/Tugas/HalamanPenilaian/DetailSiswa"
						),
				meta: {
					title: "Penilaian Tugas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
		],
	},
	{
		path: "/asesmen",
		name: "Asesmen",
		redirect: "/asesmen/ujian",
		component: () =>
			import(
				/* webpackChunkName: "asesment-redirect" */
				"../views/Asesmen/"
				),
		meta: {
			title: "Asesmen | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"Teacher",
			],
		},
		children: [
			{
				path: "ujian",
				name: "Ujian",
				component: () =>
					import(
						/* webpackChunkName: "ujian" */
						"../views/Asesmen/Ujian"
						),
				meta: {
					title: "Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ujian/penilaian",
				name: "UjianPenilaian",
				component: () =>
					import(
						/* webpackChunkName: "penilaian ujian" */
						"../views/Asesmen/Ujian/Penilaian"
						),
				meta: {
					title: "Penilaian Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ujian/penilaian/:id",
				name: "EditUjianPenilaian",
				component: () =>
					import(
						/* webpackChunkName: "penilaian ujian" */
						"../views/Asesmen/Ujian/Penilaian/FormDetailPenilaian"
						),
				meta: {
					title: "Detail Penilaian Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ujian/penilaian/:ujianId/detail-siswa/:id",
				name: "DetailSiswaUjian",
				component: () =>
					import(
						/* webpackChunkName: "penilaian ujian detail siswa" */
						"../views/Asesmen/Ujian/Penilaian/DetailSiswa"
						),
				meta: {
					title: "Detail Siswa | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ujian/penilaian/cek-jawaban/:id/siswa/:siswaId",
				name: "DetailJawabanUjian",
				component: () =>
					import(
						/* webpackChunkName: "penilaian cek jawaban" */
						"../views/Asesmen/Ujian/Penilaian/FormDetailPenilaian/CekJawaban"
						),
				meta: {
					title: "Cek Jawaban Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "ujian/:id/soal",
				name: "SoalUjian",
				component: () =>
					import(
						/* webpackChunkName: "soal-ujian" */
						"../views/Asesmen/Ujian/FormHalamanCreateSoal"
						),
				// component: () =>
				//     import (
				//         /* webpackChunkName: "Soal ujian" */
				//         '../views/Asesmen/Ujian/HalamanLama/ExamQuestionAKM.vue'
				//     ),
				meta: {
					title: "Soal Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "jadwal-ujian",
				name: "JadwalUjian",
				component: () =>
					import(
						/* webpackChunkName: "Jadwal ujian" */
						"../views/Asesmen/JadwalUjian"
						),
				meta: {
					title: "Jadwal Ujian | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
		],
	},

	{
		path: "/cetak-kartu-pelajar/:id?/:type?",
		name: "CetakKartuPelajar",
		component: () =>
			import(
				/* webpackChunkName: "cetak-kartu-pelajar" */
				"../views/Pengguna/KartuPelajar/PreviewCetak"
				),
		meta: {
			title: "Kartu Pelajar | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
			],
		},
		beforeEnter: requirePermission,
	},

	{
		path: "/pengguna/detail-pelajar/:id/cetak-s4",
		name: "DetailPelajarCetakS4",
		component: () =>
			import(
				/* webpackChunkName: "filestorage" */
				"../views/Pengguna/Cetak"
				),
		meta: {
			title: "Detail Peserta Didik | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
			],
		},
		beforeEnter: requirePermission,
	},

	// page presensi atau klastime
	{
		path: "/presensi",
		name: "Presensi",
		redirect: {name: "PresensiKelas"},
		component: () =>
			import(/* webpackChunkName: "klastime" */ "../views/Klastime"),
		meta: {
			title: "Klastime | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance",
			],
		},
		children: [
			// cctv ai START =====================
			{
				path: "pengguna-absensi-cctv",
				name: "AbsensiCctvPengguna",
				component: () =>
					import(
						/* webpackChunkName: "klastime-absensi-cctv-pengguna" */
						"../views/Klastime/PenggunaAbsensiCctv"
						),
				meta: {
					title: "Absensi Cctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "absensi-cctv",
				name: "AbsensiCctv",
				component: () =>
					import(
						/* webpackChunkName: "klastime-absensi-cctv" */
						"../views/Klastime/AbsensiCctv"
						),
				meta: {
					title: "Absensi Cctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "absensi-cctv/:id",
				name: "AbsensiCctvDetailNvr",
				component: () =>
					import(
						/* webpackChunkName: "klastime-absensi-cctv-ip" */
						"../views/Klastime/AbsensiCctv/Pengaturan"
						),
				meta: {
					title: "List Cctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "absensi-cctv/:id/cctv/:cctv_id",
				name: "AbsensiCctvDetailDeviceCctv",
				component: () =>
					import(
						/* webpackChunkName: "klastime-absensi-cctv-ip-detail" */
						"../views/Klastime/AbsensiCctv/Pengaturan/IpCctv/Pengaturan"
						),
				meta: {
					title: "List Cctv | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "pelajar-cctv/:id",
				name: "TambahPelajarCctv",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pelajar-cctv" */
						"../views/Klastime/AbsensiCctv/Pengaturan/IpCctv/Pengaturan/Pelajar/Tambah"
						),
				meta: {
					title: "Diskola Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			{
				path: "guru-staff-cctv/:id",
				name: "TambahGuruCctv",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pelajar-cctv" */
						"../views/Klastime/AbsensiCctv/Pengaturan/IpCctv/Pengaturan/GuruStaff/Tambah"
						),
				meta: {
					title: "Diskola Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance"
					]
				},
				beforeEnter: requirePermission
			},
			// cctv ai END =====================

			{
				path: "mesin-absensi",
				name: "MesinAbsensi",
				component: () =>
					import(
						/* webpackChunkName: "klastime-mesin-absensi" */
						"../views/Klastime/MesinAbsensi"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "guru-staff-mesin/:id",
				name: "TambahUserMesin",
				component: () =>
					import(
						/* webpackChunkName: "klastime-guru-staff-mesin" */
						"../views/Klastime/MesinAbsensi/Pengaturan/GuruStaff/Tambah"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pelajar-mesin/:id",
				name: "TambahPelajarMesin",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pelajar-mesin" */
						"../views/Klastime/MesinAbsensi/Pengaturan/Pelajar/Tambah"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "mesin-absensi/:id",
				name: "PengaturanMesinAbsensi",
				component: () =>
					import(
						/* webpackChunkName: "klastime-mesin-absensi-pengaturan" */
						"../views/Klastime/MesinAbsensi/Pengaturan"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pengaturan-sidikjari/:device_id/:id",
				name: "PengaturanSidikJari",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pengaturan-sidikjari" */
						"../views/Klastime/MesinAbsensi/Pengaturan/GuruStaff/Pengaturan"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "presensi-guru-staff",
				name: "PresensiGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-guru-staff" */
						"../views/Klastime/PresensiGuru"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "presensi-guru-staff/rekap-presensi",
				name: "RekapPresensiGuruStaff",
				component: () =>
					import(
						/* webpackChunkName: "klastime-rekap-presensi-guru-staff" */
						"../views/Klastime/PresensiGuru/RekapPresensi"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "presensi-pelajar",
				name: "PresensiPelajar",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-pelajar" */
						"../views/Klastime/PresensiPelajar"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "presensi-pelajar/rekap-presensi",
				name: "RekapPresensiPelajar",
				component: () =>
					import(
						/* webpackChunkName: "klastime-rekap-presensi-pelajar" */
						"../views/Klastime/PresensiPelajar/RekapPresensi"
						),
				meta: {
					title: " Produk | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
					],
				},
				beforeEnter: requirePermission,
			},

			{
				path: "presensi-kelas",
				name: "PresensiKelas",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-kelas" */
						"../views/Klastime/PresensiKelas"
						),
				meta: {
					title: "Presensi | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "presensi-kelas/absen",
				name: "PresensiKelasAbsen",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-kelas" */
						"../views/Klastime/PresensiKelas/Absensi"
						),
				meta: {
					title: "Presensi Kelas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "presensi-kelas/absen/:id/edit",
				name: "PresensiKelasEdit",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-kelas" */
						"../views/Klastime/PresensiKelas/Absensi"
						),
				meta: {
					title: "Presensi Kelas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "presensi-kelas/rekap",
				name: "PresensiKelasRekap",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-kelas" */
						"../views/Klastime/PresensiKelas/Rekap"
						),
				meta: {
					title: "Rekap Presensi Kelas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "presensi-kelas/rekap/guru-mengajar",
				name: "PresensiKelasRekapGuruMengajar",
				component: () =>
					import(
						/* webpackChunkName: "klastime-presensi-kelas" */
						"../views/Klastime/PresensiKelas/Rekap/RekapGuruMengajar.vue"
						),
				meta: {
					title: "Rekap Presensi Kelas | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"Teacher",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "kalender-sekolah",
				name: "KalenderSekolah",
				component: () =>
					import(
						/* webpackChunkName: "klastime-kalender-sekolah" */
						"../views/Klastime/KalenderSekolah"
						),
				meta: {
					title: "Kalender Sekolah | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
			{
				path: "pengaturan-jam",
				name: "PengaturanJam",
				component: () =>
					import(
						/* webpackChunkName: "klastime-pengaturan-jam" */
						"../views/Klastime/PengaturanJam"
						),
				meta: {
					title: "Pengaturan Jam | ",
					allowedRole: [
						"Head Master",
						"SuperAdmin",
						"Vice Principal",
						"School Admin",
						"School Finance",
					],
				},
				beforeEnter: requirePermission,
			},
		],
	},

	{
		path: "/verify-email",
		name: "VerifikasiEmail",
		component: () =>
			import(
				/* webpackChunkName: "verifikasi-email" */
				"../views/VerificationEmail"
				),
		meta: {
			title: "Verifikasi Email | ",
		},
	},

	...Konseling,

	...ControlKiosk,
	...WebSchool,

	// ================== KANTIN ==================
	...KantinDigital,
	// =============== KANTIN ==================

	// =============== KOPERASI ==================
	...Koperasi,
	// =============== KOPERASI ==================

	/**
	 * SPP
	 * */
	...PembayaranSpp,

	/**
	 * DANA PARTISIPASI
	 * */
	...DanaPartisipasi,

	/**
	 * KEUANGAN SEKOLAH
	 * */
	...KeuanganSekolah,


	{
		path: "/presensi-pelajar/rekap-presensi-bulanan/cetak/:id",
		name: "RekapPresensiPelajarBulanan",
		component: () =>
			import(
				/* webpackChunkName: "klastime-rekap-presensi-pelajar-cetak" */
				"../views/Klastime/PresensiPelajar/RekapPresensi/ModalBulananTahunan/RekapDokumenBulanan"
				),
		meta: {
			title: "Diskola Produk | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},
	{
		path: "/presensi-guru/rekap-presensi-bulanan/cetak/:id",
		name: "CetakRekapPresensiPelajar",
		component: () =>
			import(
				/* webpackChunkName: "klastime-rekap-presensi-guru-cetak" */
				"../views/Klastime/PresensiGuru/RekapPresensi/ModalBulananTahunan/RekapDokumenBulanan"
				),
		meta: {
			title: "Diskola Produk | ",
			allowedRole: [
				"Head Master",
				"SuperAdmin",
				"Vice Principal",
				"School Admin",
				"School Finance"
			]
		},
		beforeEnter: requirePermission
	},

	{
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: () => import(/* webpackChunkName: "not-found-page" */"../views/NotFound"),
		meta: {
			title: "Diskola Produk | ",
			allowedRole: ["all"],
		},
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

function shouldRefreshToken(timeCreate) {
	const currentTime = moment();
	const tokenCreateTime = moment(timeCreate, "YYYY-MM-DD HH:mm");
	const diffInMinutes = currentTime.diff(tokenCreateTime, "minutes");

	return diffInMinutes > 50;
}

router.beforeEach(async (to, from, next) => {
	const defaultTitle = "Portal Sekolah";
	const pageTitle = (to.meta.title || "") + (process.env.VUE_APP_TITLE || "");

	document.title = pageTitle || defaultTitle;
	let LocalDataVuex = JSON.parse(window.localStorage.getItem("storediskola"));
	const isAuthenticated = !!(
		LocalDataVuex &&
		LocalDataVuex.userToken &&
		LocalDataVuex.userToken !== ""
	);
	// ambil token koperasi kalo sudah login
	if (isAuthenticated) {
		let userRolesInStoreUser = store.state.user.role;
		let userRole = _.pluck(userRolesInStoreUser, "name");

		let levelAccessInTokenKoperasi = ["Head Master", "Cooperative"];
		let checkPermission = userRole.some(
			(r) => levelAccessInTokenKoperasi.indexOf(r) >= 0,
		);

		if (checkPermission) {
			if (
				LocalDataVuex.tokenKoperasi &&
				shouldRefreshToken(LocalDataVuex.tokenKoperasi.timeout)
			) {
				store.dispatch("checkTokenKoperasi");
			} else {
				store.dispatch("checkTokenKoperasi");
			}
		}

		if (
			LocalDataVuex.tokenDanaPartisipasi &&
			shouldRefreshToken(LocalDataVuex.tokenDanaPartisipasi.timeout)
		) {
			await store.dispatch("checkTokenDanaPartisipasi");
		} else {
			await store.dispatch("checkTokenDanaPartisipasi");
		}
	}

	if (window.innerWidth <= 768) {
		await store.commit("updateState", {
			showsidebar: false,
		});
	} else {
		await store.commit("updateState", {
			showsidebar: true,
		});
	}

	const publicPathOnly = ["VerifikasiEmail", "KebijakanPrivasi", "DeepLinkIos"];
	const publicPath = ["Login", "ForgotPassword", "ResetPassword"];

	if (!to.name) {
		next();
	} else if (publicPathOnly.includes(to.name)) {
		next();
	} else if (!publicPath.includes(to.name) && !isAuthenticated) {
		if (to.path !== "/") {
			sessionStorage.setItem("lastVisitedURL", to.path);
		}
		next({name: "Login"});
	} else if (publicPath.includes(to.name) && isAuthenticated) {
		next({name: "Dashboard"});
	} else {
		next();
	}
});

export function requirePermission(to, from, next) {
	let userRolesInStoreUser = store.state.user.role;
	let userRole = _.pluck(userRolesInStoreUser, "name");

	let routePermission = to.meta.allowedRole ? to.meta.allowedRole : [];
	let allowedAll = _.contains(routePermission, "all");

	//let userProducts = store.state.user.school?.products || [];
	//console.log(isSchoolProductsLocked(userProducts))
	/*if (
		[
			"CetakInvoice",
			"BerandaProdukPembayaran",
			"BerandaProdukPembayaranCheckout",
		].indexOf(to.name) === -1 &&
		!(
			to.name.toLowerCase().startsWith("koperasi") ||
			to.name.toLowerCase().startsWith("kantin") ||
			to.name.toLowerCase().startsWith("produkkonseling") ||
			to.name.toLowerCase().startsWith("klaskonseling")
		)
	) {
		//console.log(["Free", "CetakInvoice", "BerandaProdukPembayaran", "BerandaProdukPembayaranCheckout",].indexOf(to.name) === -1)
		//console.log(!(to.name.toLowerCase().startsWith("koperasi") || to.name.toLowerCase().startsWith("kantin") || to.name.toLowerCase().startsWith("produkkonseling") || to.name.toLowerCase().startsWith("klaskonseling")))
		//console.log('Disini')
		next({name: "Dashboard"});
		return false;
	}*/

	if (allowedAll) {
		next();
	} else {
		let checkPermission = userRole.some((r) => routePermission.indexOf(r) >= 0);

		if (!checkPermission) {
			if (to.name === "Jurusan") {
				let checkTeacher = _.contains(userRole, "Teacher");

				if (checkTeacher) {
					next({name: "Materi"});
				} else {
					next({name: "Dashboard"});
				}
			} else if (to.name === "MesinAbsensi") {
				/* cek ketika di child klastime ada yang bisa dibuka sama teacher */
				let checkTeacher = _.contains(userRole, "Teacher");

				if (checkTeacher) {
					next({name: "PresensiKelas"});
				} else {
					next({name: "Dashboard"});
				}
			} else {
				let checkCooperative = _.contains(userRole, "Cooperative");
				let checkLibrarian = _.contains(userRole, "Libraryan");
				let checkTeacher = _.contains(userRole, "Teacher");

				if (checkCooperative) {
					next({name: "Koperasi"});
				} else if (checkLibrarian) {
					next({name: "Klaspustaka"});
				} else if (checkTeacher) {
					next({name: "Materi"});
				} else {
					next({name: "Dashboard"});
				}
			}
		}
		next();
	}
}

export default router;
