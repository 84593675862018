import repository from "@/repositories/KeuanganSekolahRepository";
import StrukturErrorService from "@/services/StrukturErrorService";
import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers";

export default class KeuanganSekolahService {
	constructor() {
		this.dataRepository = repository;
	}

	async schoolMutationGet(payload) {
		const {
			filterDateStart,
			filterDateEnd,
			page, limit,
			sort_field, sort_dir
		} = payload

		try {
			const params = {
				date_start: filterDateStart || undefined,
				date_end: filterDateEnd || undefined,

				limit: limit || undefined,
				page: page || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
			}

			return await this.dataRepository.getSchoolMutation(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async schoolMutationRecapGet(payload) {
		const {
			month_end, year_end,
			month_start, year_start,
			page, limit, filterQ,
			sort_field, sort_dir
		} = payload

		try {
			const params = {
				month_start: month_start || undefined,
				year_start: year_start || undefined,

				month_end: month_end || undefined,
				year_end: year_end || undefined,

				limit: limit ?? undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
			}

			return await this.dataRepository.getSchoolMutationRecap(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async withdrawGet(payload) {
		const {
			tglawal, tglakhir, filterBank,
			q,
			page, limit,
		} = payload

		try {
			const params = {
				bank: filterBank || undefined,
				tglawal: tglawal || undefined,
				tglakhir: tglakhir || undefined,

				limit: limit ?? 10,
				page: page || 1,
				q: q || undefined,
			}

			return await this.dataRepository.getWithdraw(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async validationExecutor(obj) {
		const requiredKeys = Object.keys({
			"user_uuid": "",
			"user_name": "",
			"user_phone": "",
			"user_role_name": "",
			"school_id": "",
			"school_name": "",
		}); // Key referensi dari objek `a`
		const objectKeys = Object.keys(obj); // Key dari objek yang diperiksa

		// Periksa apakah semua key dari `requiredKeys` ada di `objectKeys`
		const missingKeys = requiredKeys.filter((key) => !objectKeys.includes(key));

		// Periksa apakah ada key tambahan yang tidak sesuai referensi
		const extraKeys = objectKeys.filter((key) => !requiredKeys.includes(key));

		// Periksa apakah ada nilai yang kosong
		const emptyKeys = requiredKeys.filter((key) => !obj[key]);

		if (missingKeys.length > 0) {
			throw new Error(`Error: Missing key(s): ${missingKeys.join(", ")}`);
		}

		if (extraKeys.length > 0) {
			throw new Error(`Error: Extra key(s) found: ${extraKeys.join(", ")}`);
		}

		if (emptyKeys.length > 0) {
			throw new Error(`Error: Key(s) with empty values: ${emptyKeys.join(", ")}`);
		}
	}

	async withdrawPost(payload = {}) {
		const {nominal, bank_account_id, executor} = payload

		if (typeof nominal !== "number") {
			throw new Error(`Nominal ${nominal} is required and must be a number`);
		}
		if (!bank_account_id) {
			throw new Error(`params not complete`);
		}
		if (!executor) {
			throw new Error(`params not complete`);
		}

		this.validationExecutor(executor)

		try {
			const formData = {
				"invoice_id": "9999", // Optional
				"nominal": nominal,
				"bank_account_id": parseInt(bank_account_id),
				"executor": executor
			}

			return await this.dataRepository.postWithdraw(formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			ShowAlertIsServerErrorOrNoInternet(parsedError)
			throw parsedError;
		}
	}

}
