import repository from "@/repositories/PembayaranSppRepository"
import StrukturErrorService from "@/services/StrukturErrorService"
//import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers"

export default class PembayaranSppService {
	constructor() {
		this.dataRepository = repository
	}

	async listStudentFeeInvoiceGet(payload) {

		const {months, user_id, class_id} = payload
		if (typeof months !== "object") {
			throw new Error("months must type object")
		}
		if (!user_id && !class_id) {
			throw new Error("!user_id and !class_id, one of them must be filled in")
		}

		try {
			const params = {
				months,
				user_id: user_id || undefined,
				class_id: class_id || undefined,
			}
			return await this.dataRepository.getStudentInvoice(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async listDiscountGet(id, payload) {

		const {
			months, user_id, class_id,
			page, limit, filterQ,
			sort_field, sort_dir
		} = payload

		if (typeof months !== "object") {
			throw new Error("months must type object")
		}

		try {
			const params = {
				months,
				user_id: user_id || undefined,
				class_id: class_id || undefined,

				limit: limit ?? undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
			}
			return await this.dataRepository.getDiscount(id, params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async listInvoiceGet(id, payload) {

		if (!id) {
			throw new Error("ID required")
		}

		const {
			page, limit, filterQ,
			sort_field, sort_dir
		} = payload

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
			}
			return await this.dataRepository.getInvoice(id, params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async studentDebtGet(payload) {

		const {
			page, limit, filterQ,
			sort_field, sort_dir,
			school_class_id,
			school_major_id,
			show
		} = payload

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,
				school_class_id: school_class_id || undefined,
				school_major_id: school_major_id || undefined,
				show: show || undefined,
			}
			return await this.dataRepository.getStudentDebt(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async sppStudentFeeInvoiceGet(payload) {

		const {
			page, limit, filterQ,
			sort_field, sort_dir,
			paid_start, paid_end,
			issued_end, issued_start,
			filterClasses, filterStatus,
		} = payload

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,
				sort_field: sort_field || undefined,
				sort_dir: sort_dir || undefined,

				classes: filterClasses || undefined,
				filter: filterStatus || undefined,

				issued_start: issued_start || undefined,
				issued_end: issued_end || undefined,

				paid_start: paid_start || undefined,
				paid_end: paid_end || undefined,
			}

			return await this.dataRepository.getSppStudentFeeInvoice(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async tagihanSppPengaturanGet(payload) {

		const {
			page, limit, filterQ,
			filterClasses, filterMajor,
		} = payload

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,

				classes: filterClasses || undefined,
				major: filterMajor || undefined,
			}

			return await this.dataRepository.getTagihanSppPengaturan(params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async listTagihanSppPengaturanByIdGet(classId, payload) {
		const {
			page, limit, filterQ,
			filterClasses, filterMajor,
		} = payload

		if (!classId) {
			throw new Error("ID required")
		}

		try {
			const params = {
				limit: limit || undefined,
				page: page || undefined,
				q: filterQ || undefined,

				classes: filterClasses || undefined,
				major: filterMajor || undefined,
			}

			return await this.dataRepository.getTagihanSppPengaturanById(classId, params)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async updateTagihanSppPengaturanPost(classId, formDataDiscount) {
		if (!classId) {
			throw new Error("ID required")
		}

		try {
			const formData = {
				data: formDataDiscount.map(item => ({
					student_id: item.student_id,
					discount: item.discount,
					remark: item.discount ? item.remark : '',
				}))
			}

			return await this.dataRepository.postTagihanSppPengaturanById(classId, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async createAndUpdateTagihanSppPengaturanPost(formBiayaSpp) {

		if (typeof formBiayaSpp !== 'object') {
			throw new Error('FormBiaya must be array key, school_class_id, classesName, fee')
		}
		if (!formBiayaSpp.length) {
			throw new Error('FormBiaya emptyArray min. 1 data')
		}

		try {
			const formData = {
				data: formBiayaSpp.map(item => ({
					key: item.key,
					school_class_id: item.school_class_id,
					grade: item.grade || null,
					classesName: item.classesName,
					fee: item.fee
				}))
			}

			return await this.dataRepository.postTagihanSppPengaturan(formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async tagihanSppPengaturanByIdAndProductIdDelete(id, idProductSpp) {

		try {
			return await this.dataRepository.updateTagihanSppPengaturanByIdAndProductId(id, idProductSpp)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async createTagihanSppPost(payload) {

		const {month, student_id, school_class_id} = payload

		try {
			const formData = {
				month: month,
				student_id: student_id,
				school_class_id: school_class_id,
			}

			return await this.dataRepository.postCreateTagihanSpp(formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async onklasProductCheckGet() {

		try {

			return await this.dataRepository.getOnklasProductCheck()
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

	async setAdminSppPost(id, payload) {
		if (!id) {
			throw new Error("ID required")
		}

		const {product_klaspay} = payload

		try {

			const formData = {
				data: product_klaspay
			}

			return await this.dataRepository.postSetAdminSpp(id, formData)
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error)
			console.log(parsedError)
			throw parsedError
		}
	}

}
