import repository from "@/repositories/RequestUserRepository";
import StrukturErrorService from "@/services/StrukturErrorService";
import {ShowAlertIsServerErrorOrNoInternet} from "@/utils/Helpers";

export default class RequestUserService {
	constructor() {
		this.dataRepository = repository;
	}

	async requestUserSummaryGet() {
		try {
			return await this.dataRepository.getRequestUserSummary()
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	/**
	 * STUDENT
	 * */

	async studentRequestUserGet(payload) {

		if (typeof payload !== "object") {
			throw new Error("payload not same");
		}

		const {page, limit, filterStatus, filterQ, sortField, sortDir, filterClass, filterGender} = payload;

		if (!filterStatus) {
			throw new Error("filterStatus required");
		}

		if (!["pending", "reject"].includes(filterStatus)) {
			throw new Error("filterStatus must pending , reject");
		}

		const params = {
			page: page || undefined,
			limit: limit || undefined,
			sort_field: sortField || undefined,
			sort_dir: sortDir || undefined,
			q: filterQ || undefined,
			status: filterStatus,
			filter_class: filterClass || undefined,
			filter_gender: filterGender !== "all" ? filterGender : undefined,
		}

		try {
			return await this.dataRepository.getRequestUserStudent(params);
		} catch (error) {
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async studentApproveRequestUserPost(userTempoIds) {

		if (typeof userTempoIds !== "object") {
			throw new Error("param not same");
		}
		if (!userTempoIds.length) {
			throw new Error("param empty array");
		}

		const formData = {
			user_tempo_id: userTempoIds
		}

		try {
			return await this.dataRepository.postRequestUserStudentApprove(formData);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);
			
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async studentRejectRequestUserPost(userTempoIds) {

		if (typeof userTempoIds !== "object") {
			throw new Error("param not same");
		}
		if (!userTempoIds.length) {
			throw new Error("param empty array");
		}

		const formData = {
			user_tempo_id: userTempoIds
		}

		try {
			return await this.dataRepository.postRequestUserStudentReject(formData);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async studentDeleteRequestUserDelete(id) {

		if (typeof id !== "number") {
			throw new Error("param not same");
		}

		try {
			return await this.dataRepository.deleteRequestUserStudent(id);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	/**
	 * TEACHER
	 * */

	async teacherRequestUserGet(payload) {

		if (typeof payload !== "object") {
			throw new Error("payload not same");
		}

		const {page, limit, filterStatus, filterQ, sortField, sortDir, filterRole, filterGender} = payload;

		if (!filterStatus) {
			throw new Error("filterStatus required");
		}

		if (!["pending", "reject"].includes(filterStatus)) {
			throw new Error("filterStatus must pending , reject");
		}

		const params = {
			page: page || undefined,
			limit: limit || undefined,
			sort_field: sortField || undefined,
			sort_dir: sortDir || undefined,
			q: filterQ || undefined,
			status: filterStatus,
			filter_role: filterRole || undefined,
			filter_gender: filterGender !== "all" ? filterGender : undefined,
		}

		try {
			return await this.dataRepository.getRequestUserTeacher(params);
		} catch (error) {

			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async teacherApproveRequestUserPost(userTempoIds) {
		if (typeof userTempoIds !== "object") {
			throw new Error("param not same");
		}
		if (!userTempoIds.length) {
			throw new Error("param empty array");
		}

		const formData = {
			user_tempo_id: userTempoIds
		}

		try {
			return await this.dataRepository.postRequestUserTeacherApprove(formData);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);
		
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async teacherRejectRequestUserPost(userTempoIds) {

		if (typeof userTempoIds !== "object") {
			throw new Error("param not same");
		}
		if (!userTempoIds.length) {
			throw new Error("param empty array");
		}

		const formData = {
			user_tempo_id: userTempoIds
		}

		try {
			return await this.dataRepository.postRequestUserTeacherReject(formData);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);

			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

	async teacherDeleteRequestUserDelete(id) {

		if (typeof id !== "number") {
			throw new Error("param not same");
		}

		try {
			return await this.dataRepository.deleteRequestUserTeacher(id);
		} catch (error) {

			ShowAlertIsServerErrorOrNoInternet(error);
		
			const parsedError = StrukturErrorService.parseError(error);
			//console.log(parsedError);
			throw parsedError;
		}
	}

}
