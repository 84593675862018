import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

const dataState = createPersistedState({
	key: "storediskola",
	paths: [
		"user",
		"userToken",
		"tokenKoperasi",
		"tokenDanaPartisipasi",
		"isDefaultPass",
		"introState",
		"tutorial",
		"menuAddOns",
		"cabdinToken",
		"dataCabdin",
	],
});

import moment from "moment";
import {_} from "vue-underscore";
import {ApiGetRequest, ApiDeleteRequest} from "@/utils/Api";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";

import Intro from "./Intro";
import Roles from "./Roles";
import Provinces from "./Provinces";
import Cities from "./Cities";
import Districts from "./Districts";
import Pengumuman from "./Pengumuman";
import Sekolah from "./Sekolah";

// ==================Pembelajaran=====================
import Jurusan from "./Pembelajaran/Jurusan";
import Materi from "./Pembelajaran/Materi";
import Pengguna from "./Pengguna";
import PenggunaSearchStudent from "./Pengguna/SearchStudent";
import PenggunaMoveClass from "./Pengguna/Pelajar/StudentMoveClass";
import ValidasiData from "./Pengguna/ValidasiData";
import GuruStaff from "./Pengguna/GuruStaff";
import Pelajar from "./Pengguna/Pelajar";
import UserTemporary from "./Pengguna/UserTemporary";
import RequestUser from "./Pengguna/RequestUser";
import FileStorage from "./Pengguna/FileStorage";
import KartuPelajar from "./Pengguna/KartuPelajar";
import RuangKelas from "./Pembelajaran/RuangKelas";
import SummaryClass from "./Pembelajaran/SummaryClass";
import Ujian from "./Pembelajaran/Ujian";
import JadwalUjian from "./Pembelajaran/JadwalUjian";
import Tugas from "./Pembelajaran/Tugas";
import Mapel from "./Pembelajaran/Mapel";
import JadwalPelajaran from "./Pembelajaran/Jadwal";
import Pembelajaran from "./Pembelajaran";
import Classrooms from "./Classrooms";
import Majors from "./Major";
// ==================Pembelajaran=====================

// ==================Pembelajaran Magang=====================
import Magang from "./Pembelajaran/Magang";
import MagangSettingGroup from "./Pembelajaran/Magang/SettingGroup";
import MagangReport from "./Pembelajaran/Magang/ReportMagang";
import MagangJadwal from "./Pembelajaran/Magang/JadwalMagang";
// ==================Pembelajaran Magang=====================

// ==================Pembelajaran Ujian=====================
import UjianSoal from "./Pembelajaran/Ujian/Soal";
import PenilaianSoal from "./Pembelajaran/Ujian/Penilaian";
import CekJawabanSoal from "./Pembelajaran/Ujian/CekJawaban";
// ==================Pembelajaran Ujian=====================

// ==================Beranda=====================
import Beranda from "./Beranda";
import BerandaProduk from "./Beranda/Produk";
import BerandaPembelian from "./Beranda/Pembelian";
import BerandaTagihan from "./Beranda/Tagihan";
import TagihanTryout from "./Beranda/Tagihan/tagihanTryOut";
import BerandaTryout from "./Beranda/Tryout";
import JawabanTryOut from "./Beranda/Tryout/jawabanTryOut";
import VerificationEmail from "./VerificationEmail";
import Payment from "./Payment";
import BerandaStatistik from "./Beranda/Statistik";
import BerandaOtp from "./Beranda/Otp";
// ==================Beranda=====================

// ==================Profile Sekolah=====================
import PengaturanHakAkses from "./PengaturanHakAkses";
import Profile from "./Profile";
// ==================Profile Sekolah=====================

// ==================Klastime=====================
import Klastime from "./Klastime";
import MesinAbsensi from "./Klastime/MesinAbsensi";
import AbsensiCctv from "./Klastime/MesinAbsensiCctv";
import PenggunaAbsensiCctv from "./Klastime/PenggunaAbsensiCctv";
import PengaturanJam from "./Klastime/PengaturanJam";
import KalenderSekolah from "./Klastime/KalenderSekolah";
import PresensiGuru from "./Klastime/PresensiGuru";
import RekapPresensiGuru from "./Klastime/PresensiGuru/RekapPresensi";
import RekapPresensiGuruBulanan from "./Klastime/PresensiGuru/RekapPresensi/Bulanan";
import RekapPresensiGuruTahunan from "./Klastime/PresensiGuru/RekapPresensi/Tahunan";
import PresensiKelas from "./Klastime/PresensiKelas";
import PresensiPelajar from "./Klastime/PresensiPelajar";
import RekapPresensiPelajar from "./Klastime/PresensiPelajar/RekapPresensi";
import RekapPresensiPelajarBulanan from "./Klastime/PresensiPelajar/RekapPresensi/Bulanan";
import RekapPresensiPelajarTahunan from "./Klastime/PresensiPelajar/RekapPresensi/Tahunan";
// ==================Klastime=====================

// ==================Klaskonseling=====================

import KlasKonseling from "./KlasKonseling";
import KlasKonselingKamusPoin from "./KlasKonseling/KamusPoin";
import KlasKonselingPenanganan from "./KlasKonseling/Penanganan";
import KlasKonselingPengaturan from "./KlasKonseling/Pengaturan";
import KlasKonselingPoinWarna from "./KlasKonseling/PoinWarna";
import KlasKonselingRapor from "./KlasKonseling/Rapor";
import KlasKonselingAssesment from "./KlasKonseling/Asesmen";
import KlasKonselingTutupBuku from "./KlasKonseling/TutupBuku";
import KlasKonselingAutoGenerate from "./KlasKonseling/AutoGenerate";

// ==================Klaskonseling=====================
import AgendaSurat from "./AgendaSurat/index";
import AgendaSuratMasuk from "./AgendaSurat/SuratMasuk/index";
import AgendaSuratMasukSekolah from "./AgendaSurat/SuratMasukSekolah/index";

import DapodikJson from "./DapodikJson";

// ==================Klasite=====================
import Klasite from "./Klasite";
import KlasiteBkk from "./Klasite/Bkk";
import KlasitePpdb from "./Klasite/Ppdb";
import KlasitePpdbPendaftar from "./Klasite/Ppdb/Pendaftar";
import KlasitePpdbContent from "./Klasite/Ppdb/Content";
import KlasitePpdbSettings from "./Klasite/Ppdb/Settings";
import KlasiteSchoolPaid from "./Klasite/SchoolPaid";
// ==================Klasite=====================

// ==================DOKUMEN PKKS=====================
import DocumentPkks from "./Sekolah/DocumentPkks";

// ==================DOKUMEN INOVASI=====================
import DocumentInovasi from "./Sekolah/DocumentInovasi";

// ==================DOKUMEN Graduation=====================
import DocumentGraduation from "./Sekolah/DocumentGraduation";

// ==================DOKUMEN SNPMB=====================
import DocumentSNPMB from "./Sekolah/DocumentSNPMB";

// ==================DOKUMEN DocumentPenghargaanPrestasi=====================
import DocumentPenghargaanPrestasi from "./Sekolah/DocumentPenghargaanPrestasi";
// ==================Klasite=====================

// ==================Koperasi=====================
import KoperasiPayment from "./Koperasi/Payment";
import KoperasiKas from "./Koperasi/Kas";
import KoperasiDaftarKantin from "./Koperasi/Kantin/DaftarKantin";
import KoperasiManagementKantin from "./Koperasi/Kantin/ManagementKantin";
import KoperasiDanaKantin from "./Koperasi/Kantin/DanaKantin";
import KoperasiPenjualanKantin from "./Koperasi/Kantin/Penjualan";
import QrCodeKantin from "./Koperasi/Kantin/QrCodeKantin";
// ==================Koperasi=====================

import VerificationCode from "./VerificationCode";

// ==================Keuangan =====================
import Keuangan from "./Keuangan";
import KeuanganMutasi from "./Keuangan/Mutasi";
import KeuanganAccountBank from "./Keuangan/AccountBank";
// ==================Keuangan =====================

// ==================Dana Sekolah =====================
import PembayaranSpp from "./PembayaranSpp";
import PembayaranSppTunggakan from "./PembayaranSpp/Tunggakan";
import PembayaranSppDibayar from "./PembayaranSpp/Dibayar";
import PembayaranSppPengaturan from "./PembayaranSpp/Pengaturan";
// ==================Dana Sekolah =====================

// ==================Dana Partisipasi =====================
import DanaPartisipasi from "./DanaPartisipasi";
import DanaPartisipasiProgram from "./DanaPartisipasi/Program";
import DanaPartisipasiCreateProgram from "./DanaPartisipasi/CreateProgram";
import DanaPartisipasiByPelajar from "./DanaPartisipasi/ByPelajar";
// ==================Dana Partisipasi =====================

import ControlKiosk from "./ControlKiosk";
import ControlKioskImages from "./ControlKiosk/Images";
import ControlKioskVideos from "./ControlKiosk/Videos";
import ControlKioskWebsites from "./ControlKiosk/Websites";

export default new Vuex.Store({
	modules: {
		login: Login,
		forgotPwd: ForgotPassword,
		profile: Profile,
		jurusan: Jurusan,
		materi: Materi,
		pengguna: Pengguna,
		studentMoveClass: PenggunaMoveClass,
		validasiData: ValidasiData,
		gurustaff: GuruStaff,
		pelajar: Pelajar,

		penggunaSearchStudent: PenggunaSearchStudent,

		userTemporary: UserTemporary,
		requestUser: RequestUser,
		fileStorage: FileStorage,
		kartuPelajar: KartuPelajar,
		ruangkelas: RuangKelas,
		summaryClass: SummaryClass,
		intro: Intro,
		roles: Roles,
		provinces: Provinces,
		cities: Cities,
		districts: Districts,
		majors: Majors,
		classrooms: Classrooms,
		pengumuman: Pengumuman,
		verificationEmail: VerificationEmail,
		sekolah: Sekolah,

		mapel: Mapel,
		jadwalPelajaran: JadwalPelajaran,
		pembelajaran: Pembelajaran,
		ujian: Ujian,
		jadwalUjian: JadwalUjian,
		tugas: Tugas,
		beranda: Beranda,
		berandaProduk: BerandaProduk,
		berandaStatistik: BerandaStatistik,
		berandaOtp: BerandaOtp,
		payment: Payment,
		berandaPembelian: BerandaPembelian,
		berandaTagihan: BerandaTagihan,
		tagihanTryout: TagihanTryout,
		berandaTryout: BerandaTryout,
		jawabanTryOut: JawabanTryOut,
		ujianSoal: UjianSoal,
		PenilaianSoal: PenilaianSoal,
		cekJawabanSoal: CekJawabanSoal,
		pengaturanHakAkses: PengaturanHakAkses,
		magang: Magang,
		magangSettingGroup: MagangSettingGroup,
		magangReport: MagangReport,
		magangJadwal: MagangJadwal,
		dapodik: DapodikJson,

		klastime: Klastime,
		mesinAbsensi: MesinAbsensi,
		absensiCctv: AbsensiCctv,
		penggunaAbsensiCctv: PenggunaAbsensiCctv,
		pengaturanJam: PengaturanJam,
		kalenderSekolah: KalenderSekolah,
		presensiGuru: PresensiGuru,
		rekapPresensiGuru: RekapPresensiGuru,
		rekapPresensiGuruBulanan: RekapPresensiGuruBulanan,
		rekapPresensiGuruTahunan: RekapPresensiGuruTahunan,
		presensiKelas: PresensiKelas,
		presensiPelajar: PresensiPelajar,
		rekapPresensiPelajar: RekapPresensiPelajar,
		rekapPresensiPelajarBulanan: RekapPresensiPelajarBulanan,
		rekapPresensiPelajarTahunan: RekapPresensiPelajarTahunan,

		klaskonseling: KlasKonseling,
		klaskonselingKamusPoin: KlasKonselingKamusPoin,
		klaskonselingPenanganan: KlasKonselingPenanganan,
		klaskonselingPengaturan: KlasKonselingPengaturan,
		klasKonselingPoinWarna: KlasKonselingPoinWarna,
		klasKonselingRapor: KlasKonselingRapor,
		klasKonselingAssesment: KlasKonselingAssesment,
		klasKonselingTutupBuku: KlasKonselingTutupBuku,
		klasKonselingAutoGenerate: KlasKonselingAutoGenerate,

		agendaSurat: AgendaSurat,
		suratMasuk: AgendaSuratMasuk,
		suratMasukSekolah: AgendaSuratMasukSekolah,

		klasite: Klasite,
		klasiteBkk: KlasiteBkk,
		klasitePpdb: KlasitePpdb,
		ppdbPendaftar: KlasitePpdbPendaftar,
		ppdbContent: KlasitePpdbContent,
		ppdbSettings: KlasitePpdbSettings,
		klasiteSchoolPaid: KlasiteSchoolPaid,

		docPkks: DocumentPkks,
		docInovasi: DocumentInovasi,
		docGraduation: DocumentGraduation,
		docSNPMB: DocumentSNPMB,
		docPenghargaanPrestasi: DocumentPenghargaanPrestasi,

		// ==================Koperasi=====================
		koperasiPayment: KoperasiPayment,
		kasKoperasi: KoperasiKas,
		koperasiDaftarKantin: KoperasiDaftarKantin,
		koperasiManagementKantin: KoperasiManagementKantin,
		koperasiDanaKantin: KoperasiDanaKantin,
		koperasiPenjualanKantin: KoperasiPenjualanKantin,
		qrCodeKantin: QrCodeKantin,
		// ==================Koperasi=====================

		verificationCode: VerificationCode,

		/**
		 * KEUANGAN SEKOLAH SPP
		 * */
		keuangan: Keuangan,
		keuanganMutasi: KeuanganMutasi,
		keuanganAccountBank: KeuanganAccountBank,
		pembayaranSpp: PembayaranSpp,
		pembayaranSppTunggakan: PembayaranSppTunggakan,
		pembayaranSppDibayar: PembayaranSppDibayar,
		pembayaranSppPengaturan: PembayaranSppPengaturan,
		danaPartisipasi: DanaPartisipasi,
		danaPartisipasiProgram: DanaPartisipasiProgram,
		danaPartisipasiCreateProgram: DanaPartisipasiCreateProgram,
		danaPartisipasiByPelajar: DanaPartisipasiByPelajar,

		controlKiosk: ControlKiosk,
		controlKioskImages: ControlKioskImages,
		controlKioskVideos: ControlKioskVideos,
		controlKioskWebsites: ControlKioskWebsites,

	},
	state: {
		user: {},
		stopRequest: false,
		userToken: "",
		isLoadingTokenKoperasi: false,
		is_default_password: false,
		is_phone_verified: false,
		tokenKoperasi: {
			token: "",
			timeout: "",
		},
		isLoadingTokenDanaPartisipasi: false,
		tokenDanaPartisipasi: {
			token: "",
			timeout: "",
		},
		menuAddOns: {
			menu: [],
			last_check: false,
		},
		isDefaultPass: "",
		showsidebar: true,
		introState: {
			state1: [],
			state2: [],
			state1Done: false,
		},
		tutorial: {
			step_1: false,
			step_2: false,
			step_3: false,
			step_4: false,
			step_5: false,
			step_6: false,
		},
	},
	mutations: {
		updateState(state, payload) {
			Object.keys(payload).map((item) => {
				state[item] = payload[item];
			});
		},
		logoutState(state) {
			state.user = {};
			state.userToken = "";
			state.tokenKoperasi = {
				token: "",
				timeout: "",
			};
			state.tokenDanaPartisipasi = {
				token: "",
				timeout: "",
			};
			state.introState = {
				state1: [],
				state2: [],
				state1Done: false,
			};
			state.tutorial = {
				step_1: false,
				step_2: false,
				step_3: false,
				step_4: false,
				step_5: false,
				step_6: false,
			};
			state.menuAddOns = {
				menu: [],
				last_check: "",
			};
		},
	},
	actions: {
		async fetchCurrentUser() {
		},

		async checkMenuAddOns({commit, state}) {
			// fungsi untuk cek menu add ons koperasi, dana partisipasi, dana sekolah, kewirausahaan
			// dicek setiap 60 menit atau setiap ada di menu beranda

			const {menuAddOns} = state;
			let timeRequest = 60;
			let checkAddOns = false;

			if (!menuAddOns.last_check || menuAddOns.last_check === "") {
				checkAddOns = true;
			} else {
				let timeNow = moment(new Date());
				let timeout = menuAddOns.last_check;
				if (timeNow.diff(timeout, "minutes") >= timeRequest) {
					checkAddOns = true;
				}
			}
			if (checkAddOns) {
				const result = await ApiGetRequest(`school/addons`);
				if (!result.error) {
					let dataMenuAddOns = _.where(result.data.data, {
						is_show: true,
					});

					commit("updateState", {
						menuAddOns: {
							menu: dataMenuAddOns,
							last_check: moment().format("YYYY-MM-DD HH:mm"),
						},
					});
				} else {
					commit("updateState", {
						menuAddOns: {
							menu: [],
							last_check: "",
						},
					});
				}
			}
		},

		async checkTokenKoperasi({commit, state}) {
			let endPoint = `school/cooperative/payment-token?token=koperasi`;
			const {tokenKoperasi} = state;
			let {token, timeout} = tokenKoperasi;
			let timeNow = moment(new Date());
			let available = true;

			timeout = moment(timeout);
			if (!token || token === "" || !tokenKoperasi || tokenKoperasi === "") {
				available = false;
			} else {
				// check setiap ada selisih waktu lebih dari 10 menit auto request token baru
				if (timeNow.diff(timeout, "minutes") > 10) {
					available = false;
				}
			}

			if (!available) {
				commit("updateState", {
					isLoadingTokenKoperasi: true,
				});

				const result = await ApiGetRequest(endPoint);
				//console.log(endPoint);
				//const result = await ApiMockGetRequest("school/cooperative/payment-token400");

				commit("updateState", {
					isLoadingTokenKoperasi: false,
				});

				if (!result.error) {
					commit("updateState", {
						tokenKoperasi: {
							token: result.data.data.token,
							timeout: moment().format("YYYY-MM-DD HH:mm"),
						},
					});
					return false;
				}
				commit("updateState", {
					tokenKoperasi: {
						token: "",
						timeout: "",
					},
				});
			}

			return true;
		},

		async checkTokenDanaPartisipasi({commit, state}) {
			let endPoint = `school/payment-token`;
			const {tokenDanaPartisipasi} = state;
			let {token, timeout} = tokenDanaPartisipasi;
			let timeNow = moment(new Date());
			let available = true;

			timeout = moment(timeout);
			if (
				!token ||
				token === "" ||
				!tokenDanaPartisipasi ||
				tokenDanaPartisipasi === ""
			) {
				available = false;
			} else {
				// check setiap ada selisih waktu lebih dari 10 menit auto request token baru
				if (timeNow.diff(timeout, "minutes") > 10) {
					available = false;
				}
			}

			if (!available) {
				await commit("updateState", {
					isLoadingTokenDanaPartisipasi: true,
				});
				const result = await ApiGetRequest(endPoint);
				await commit("updateState", {
					isLoadingTokenDanaPartisipasi: false,
				});

				if (!result.error) {
					commit("updateState", {
						tokenDanaPartisipasi: {
							token: result.data.data.token,
							timeout: moment().format("YYYY-MM-DD HH:mm"),
						},
					});
				} else {
					commit("updateState", {
						tokenDanaPartisipasi: {
							token: "",
							timeout: "",
						},
					});
				}
			}

			return true;
		},

		async checkIntroState({commit, state}) {
			let endPoint = `school/cms-init`;
			const {introState} = state;

			let flag = 0;
			if (introState.state1.length > 0) {
				flag++;
			} else {
				if (introState.state1Done) {
					flag++;
				}
			}

			let valIntro = _.values(introState.state2);
			valIntro = _.without(valIntro, false);

			flag += valIntro.length;

			if (flag < 7) {
				// STATE 1
				const resultState1 = await ApiGetRequest(endPoint, {
					state: 1,
				});
				if (!resultState1.error) {
					introState.state1 = resultState1.data.data[0].SuperAdmin;
				} else {
					introState.state1 = [];
				}

				// STATE 2
				const resultState2 = await ApiGetRequest(endPoint, {
					state: 2,
				});
				if (!resultState2.error) {
					introState.state2 = resultState2.data.data[0];
				} else {
					// auto logout supaya many request
					commit("logoutState");

					introState.state2 = [];
				}

				// SKIPPED STATE 1
				let statusState1 = false;
				if (introState.state1Done) {
					statusState1 = true;
				}

				let valIntroState2 = _.values(introState.state2);
				valIntroState2 = _.without(valIntroState2, false);
				if (valIntroState2.length > 0) {
					statusState1 = true;
				}

				commit("updateState", {
					introState: {
						state1: introState.state1,
						state2: introState.state2,
						state1Done: statusState1,
					},
				});
			}

			return true;
		},

		async logoutSystem({commit}) {
			await ApiDeleteRequest(`logout`);
			commit("logoutState");
		},
	},
	getters: {
		schoolAvatar: (state) => {
			try {
				return state.user.school.image;
			} catch (e) {
				return false;
			}
		},
		schoolName: (state) => {
			try {
				return state.user.school.name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolId: (state) => {
			try {
				return state.user.school.row_id;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolUuId: (state) => {
			return state?.user?.school?.id || 'not found';
		},
		schoolAddress: (state) => {
			try {
				return state.user.school.address;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolCity: (state) => {
			try {
				return state.user.school.city_name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolType: (state) => {
			return state.user?.school?.type || null;
		},
		isShowMajor: (state) => {
			const type = state.user?.school?.type || null;
			return ["SMA", "SMK", "SLB"].includes(type);
		},
		schoolProvince: (state) => {
			try {
				return state.user.school.province_name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolSubDistrict: (state) => {
			try {
				return state.user.school.sub_district_name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		schoolTotalStudent: (state) => {
			try {
				return state.user.school.total_student;
			} catch (e) {
				return 0;
			}
		},
		headMasterName: (state) => {
			try {
				return state.user.school.headmaster.name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		headMasterAddress: (state) => {
			try {
				return state.user.school.headmaster.address;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		headMasterAvatar: (state) => {
			try {
				return state.user.school.headmaster.user_avatar_image;
			} catch (e) {
				return null;
			}
		},
		headMasterPhone: (state) => {
			try {
				return state.user.school.headmaster.phone;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		rootProductsSchool: (state) => {
			try {
				return state.user.school.products;
			} catch (e) {
				return [];
			}
		},
		schoolCoordinateIsCompleteLatLng: (state) => {
			try {
				return !!(
					state.user.school.coordinate.longitude &&
					state.user.school.coordinate.latitude
				);
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		userPhoneIsVerified: (state) => {
			try {
				return state.user.is_phone_verified;
			} catch (e) {
				return false;
			}
		},
		userUuid: (state) => {
			try {
				return state.user.id;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		userName: (state) => {
			try {
				return state.user.name;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		userPhone: (state) => {
			try {
				return state.user.phone;
			} catch (e) {
				return "tidak ditemukan";
			}
		},
		userRoleToArray: (state) => {
			try {
				return state.user.role.map((item) => item.name);
			} catch (e) {
				return [];
			}
		},
		haveTokenCooperative: (state) => {
			try {
				const {tokenKoperasi} = state;
				let {token, timeout} = tokenKoperasi;
				let timeNow = moment(new Date());

				timeout = moment(timeout);
				if (!token || token === "" || !tokenKoperasi || tokenKoperasi === "") {
					return false;
				} else {
					// check setiap ada selisih waktu lebih dari 10 menit auto request token baru
					if (timeNow.diff(timeout, "minutes") > 10) {
						return false;
					}
				}
				return true;
			} catch (e) {
				return false;
			}
		},
	},
	plugins: [dataState],
});
