import axios from "axios"
import ApiNestService from "@/api/ApiNestService";

class KeuanganSekolahRepository {
	constructor() {
		this.apiService = ApiNestService
		this.cancelTokenSource = null
		this.mock = 'http://localhost:3000/api'
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.")
		}
		this.cancelTokenSource = axios.CancelToken.source()
	}

	async getSchoolMutation(params) {
		this.createCancelToken()
		return await this.apiService.get(`/payment/transaction/school_mutation`, {params})
	}

	async getSchoolMutationRecap(params) {
		return await this.apiService.get(`/payment/mutation/recap`, {params})
	}

	async getWithdraw(params) {
		return await this.apiService.get(`/payment/transaction/withdraw/list`, {params})
	}

	async postWithdraw(formData) {
		return await this.apiService.post(`/payment/transaction/withdraw/inquiry`, formData)
	}

}

export default new KeuanganSekolahRepository()
