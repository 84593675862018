import { ApiGetRequest, ApiNotification } from "@/utils/Api";
import { Message } from "element-ui";
import { SERVER_ERROR_MESSAGE } from "@/services/messageService";

const state = {
  data: {
    majors: [],
  },
};

const mutations = {
  changeMajor(state, payload) {
    state.data = Object.assign({}, state.data, payload);
  },
};

const actions = {
  async getMajor({ commit }) {
    const result = await ApiGetRequest(`school/major`);
    if (result.error) {
      if (result.code >= 500) {
        ApiNotification("error", SERVER_ERROR_MESSAGE);
        return false;
      }
      Message({
        type: "error",
        message: result.error,
      });

      return [];
    } else {
      commit("changeMajor", {
        majors: result.data.data,
      });

      return result.data.data;
    }
  },
};

const getters = {
	majorsNoAlumnus: (state) => {
		if (!state.data.majors.length) {
			return [];
		}
		return state.data.majors.filter(
			(item) => !item.name.includes("ALUMNI"),
		);
	},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
	getters
};
