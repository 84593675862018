import {Message} from "element-ui";
import {DUPLICATE_CALL_SERVER_MESSAGE, SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";
import {NO_INTERNET_MESSAGE} from "@/services/messageService";
import GlobalService from "@/services/GlobalService";
import {checkVw} from "@/services/internalService";
import {isServerErrorOrNoInternet} from "@/utils/Helpers";

const state = {
	data: {
		masterBank: {
			filterQ: "",
			limit: 0,
			page: 1,
			total: 0,
			items: [],
		},
		showModal: false,
		loading: false,
		itemsMessageTable: null,
		errorDataItems: null,
		filterQ: "",
		limit: 100,
		page: 1,
		total: 0,
		items: [],
		sortField: "",
		sortDir: "",
		ruleFormBank: {
			daftarbank: "",
			norek: "",
			atasnama: "",
			selectedBank: "",
		},
		ruleFormBankError: {
			account_number: "",
			account_holder: "",
			bank: "",
		},
		rulesBank: {
			selectedBank: [
				{
					required: true,
					message: "Anda wajib mengisi Daftar Bank",
					trigger: "blur",
				},
				{
					validator: (rule, value, callback) => {
						if (value) {
							if (
								(!state.data.ruleFormBank.selectedBank ||
									state.data.ruleFormBank.selectedBank === "") &&
								value !== ""
							) {
								callback(new Error("Data bank tidak valid"));
							} else {
								callback();
							}
						}
					},
					trigger: "change",
				},
			],
			norek: [
				{
					required: true,
					message: "Anda wajib mengisi No rekening",
					trigger: "blur",
				},
			],
			atasnama: [
				{
					required: true,
					message: "Anda wajib mengisi Nama Pemilik rekening",
					trigger: "blur",
				},
			],
		},
		columns: [
			{
				prop: "bank_name",
				label: "Bank",
				minWidth: "150",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "bank_account_number",
				label: "No Rekening",
				width: "150",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "bank_account_holder",
				label: "Atas Nama",
				minWidth: "180",
				"class-name": "body-14 col-black-2",
			},
			{
				prop: "status",
				label: "Status",
				width: "140",
				"class-name": "body-14 col-black-2",
			},
			{
				fixed: "right",
				label: "Aksi",
				width: checkVw(120),
				"class-name": "body-14 col-black-2",
			},
		],
	},
};

const mutations = {
	changeKeuanganAB(state, payload) {
		state.data = Object.assign({}, state.data, payload);
	},
	changeKeuanganABLoading(state, payload) {
		state.data.loading = payload
	},

	changeKeuanganMasterBank(state, payload) {
		state.data.masterBank = Object.assign({}, state.data.masterBank, payload);
	},
	changeKeuanganMasterBankLoading(state, payload) {
		state.data.masterBank.loading = payload
	},

	setMessageItemsTable(state, payload) {
		state.data.itemsMessageTable = payload
	},
	setDataItemsError(state, payload) {
		state.data.errorDataItems = payload
	},

	DELETE_ITEMS(state, itemsId) {
		const index = state.data.items.findIndex((item) => item.id === itemsId);
		if (index !== -1) {
			state.data.items.splice(index, 1);
		}
	},
};

const actions = {

	async onShowModal({commit}, isOpen) {
		commit("changeKeuanganAB", {showModal: isOpen})
	},

	async fetchListBank({commit, state}) {

		const {masterBank} = state.data

		if (masterBank.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeKeuanganMasterBankLoading", true)

		const params = {
			page: 1,
			limit: 0,
			sort_field: "name",
			sort_dir: "asc",
		};

		try {
			const service = new GlobalService()
			const result = await service.bankGet(params)

			const datases = result.data.data || []
			commit("changeKeuanganMasterBank", {
				items: datases,
			})

			return true
		} catch (e) {
			console.log(e)

			commit("changeKeuanganMasterBank", {
				items: [],
			})

			return false;
		} finally {
			commit("changeKeuanganMasterBankLoading", false)
		}
	},

	async postAccountBankSchoolFinance({commit, state}) {

		const {masterBank} = state.data

		if (masterBank.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeKeuanganMasterBankLoading", true)

		try {
			const service = new GlobalService()
			const result = await service.bankAccountPost(state.data.ruleFormBank)
			const dataUpdate = [result.data.data]
			commit("changeKeuanganAB", {
				items: [...dataUpdate, ...state.data.items]
			})
			commit("changeKeuanganAB", {
				ruleFormBank: {
					daftarbank: "",
					norek: "",
					atasnama: "",
					selectedBank: "",
				}
			})
			return true
		} catch (e) {
			console.log(e)
			if (isServerErrorOrNoInternet(e)) {
				return
			}
			if (e.status === 422) {
				const transformedData = {};
				for (const key in e.errorList) {
					if (Array.isArray(e.errorList[key])) {
						// Jika nilai adalah array, ambil elemen pertama (index 0) sebagai string
						transformedData[key] = e.errorList[key][0];
					} else {
						transformedData[key] = e.errorList[key];
					}
				}
				commit("changeKeuanganAB", {
					ruleFormBankError: transformedData
				})
			}
			return false;
		} finally {
			commit("changeKeuanganMasterBankLoading", false)
		}
	},

	async fetchAccountBankSchoolFinance({commit, state}) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeKeuanganABLoading", true)

		const params = {
			page: 1,
			limit: 0,
			sort_field: "name",
			sort_dir: "asc",
		};

		try {
			const service = new GlobalService()
			const result = await service.bankAccountGet(params)

			const datases = result.data.data || []
			if (result.data?.data) {
				if (!datases.length) {
					let m = data.filterQ ? "Pencarian Tidak Ditemukan" : "Belum terdapat Akun Bank"
					commit("setMessageItemsTable", m)
				} else {
					commit("setMessageItemsTable", null)
				}

				commit("changeKeuanganAB", {
					items: datases,
					total: (result.data?.meta && result.data?.meta.total) || datases.length,
				})
			}

			commit("setDataItemsError", null)

			return true
		} catch (e) {
			console.log(e)

			commit("changeKeuanganAB", {
				items: [],
				total: 0,
			})

			if (e.status >= 500) {
				commit("setDataItemsError", SERVER_ERROR_MESSAGE)
				commit("setMessageItemsTable", SERVER_ERROR_MESSAGE)
				return false
			} else if (e.code === "ERR_NETWORK") {
				commit("setDataItemsError", NO_INTERNET_MESSAGE)
				commit("setMessageItemsTable", NO_INTERNET_MESSAGE)
				return false
			}

			let m = e.error ? e.error : "Gagal Mengambil Data Rekap"
			Message({
				type: "error",
				message: m
			});

			commit("setMessageItemsTable", m)
			return false;
		} finally {
			commit("changeKeuanganABLoading", false)
		}
	},

	async deleteAccountBankSchoolFinance({commit, state}, abId) {

		const {data} = state

		if (data.loading) {
			console.log(DUPLICATE_CALL_SERVER_MESSAGE)
			return
		}

		commit("changeKeuanganABLoading", true)

		try {
			const service = new GlobalService()
			await service.bankAccountDelete(abId)
			commit("DELETE_ITEMS", abId)
			Message({
				type: "success",
				message: "Berhasil diproses!"
			});
			return true
		} catch (e) {
			console.log(e)

			if (isServerErrorOrNoInternet(e)) {
				return false
			}

			let m = e.error ? e.error : "Gagal Hapus Data"
			Message({
				type: "error",
				message: m
			});

			return false;
		} finally {
			commit("changeKeuanganABLoading", false)
		}
	},

};

const getters = {
	listAccountBankGetter: state => {
		const {filterQ, items} = state.data;
		return filterQ
			? items.filter(item => item.bank.name.toLowerCase() === filterQ.toLowerCase())
			: items;
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
